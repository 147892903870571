import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs } from '../../AbstractElements';
import { useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { Add, ExpenseSummaryRep, ExpenseSummaryList } from '../../Constant';
import SearchList from '../../CommonElements/Search';
import { viewPackageReport, viewPackageReportRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { ExpenseSummaryColumns } from '../../Data/MockTable/ReportTableHeader';

const ExpenseSummary = () => {
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();

    const updateSearchKeyword = (keyword) => {
        setSearchKeyword(keyword);
    };

    const fetchExpenseSummary = async (page, size = perPage) => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPackageReport: viewPackageReportRole +
            `?page=${page}&page_length=${size}&search=${searchKeyword}`);
            if (response.status === true) {
        setResetPaginationToggle(!resetPaginationToggle);
        setLoading(true);
        const valuePackages = response.data.data
        setCustomerList(Object.keys(valuePackages).map((e, index) => {
            return {
                Si: index + 1,
                Category: valuePackages[e].name,
                January: valuePackages[e].unit_price,
                February: valuePackages[e].unit_tax,
                March: valuePackages[e].total_price,
                April: valuePackages[e].tax_value,
                May: valuePackages[e].grand_total,
                June: valuePackages[e].invoices,
                July: valuePackages[e].total_price,
                August: valuePackages[e].tax_value,
                September: valuePackages[e].grand_total,
                October: valuePackages[e].invoices,
                November: valuePackages[e].grand_total,
                December: valuePackages[e].invoices,
            }
        }
        ))
        setLoading(false);
        setTotalRows(response.data.total);
        setLoading(false);
        
    } else if (response.status >= 400 && response.status <= 405) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (response.status >= 500 && response.status <= 505) {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
        navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
    };

    useEffect(() => {
        fetchExpenseSummary(1);
    }, [searchKeyword]);

    const handlePageChange = page => {
        fetchExpenseSummary(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchExpenseSummary(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <Fragment>
            <Breadcrumbs mainTitle={ExpenseSummaryRep} parent="Reports" title={ExpenseSummaryRep} />
            <Card>
                <CardHeader>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <H3>{ExpenseSummaryList}</H3>
                    </div>
                </CardHeader>
                <div style={{ paddingLeft: '22px', marginRight: '22px', marginBottom: '-20px' }}>
                    <div style={{ float: 'left' }}>
                        <SearchList updateSearchKeyword={updateSearchKeyword} />
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={ExpenseSummaryColumns}
                            data={customerLists}
                            progressPending={loading}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[ 10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ExpenseSummary;