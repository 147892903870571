import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import { H3, H5, Image, P } from "../../../AbstractElements";
import { CollectionsAgent, Name, Progress, Status, ThisMonth, Today } from "../../../Constant";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Transaction = (collectionAgent) => {
    const authState = useSelector((state) => state.auth);
    const agent = collectionAgent.collectionAgent;
    return (
        <Fragment>
            <Col xl="6" >
                <Card className="transaction-history">                   
                    <CardHeader className="pb-0">
                        <H3>{CollectionsAgent}</H3>
                    </CardHeader>
                    <CardBody>
                        <div className="dashboardTable">
                        <Table className="table table-bordernone table-responsive">
                            <thead> 
                                <tr>
                                    <th>{Name}</th>
                                    <th>{Today}</th>
                                    <th>{ThisMonth}</th>
                                    <th>{Progress}</th>                                 
                                </tr>
                            </thead>
                            <tbody>
                                {agent.map((item,index) => {
                                    return (<tr key={index}>
                                        <td>
                                            <div className="d-flex">
                                                <div className="flex-grow-1">
                                                    <Link style={{color:'#534686', marginLeft:'13px'}} to={`${process.env.PUBLIC_URL}/report/payment-collection?userId=expire_tomorrow`}>
                                                    <H5 >{item.name}</H5>
                                                    </Link>
                                                </div>
                                            </div>
                                        </td >
                                        <td>
                                            <H5>{authState.currency} {item.today}</H5>
                                        </td>
                                        <td>
                                            <H5>{authState.currency} {item.income}</H5>
                                        </td>
                                        <td>
                                            <div className="progress-showcase">
                                                <P>{item.progress}</P>
                                                <div className={`progress ${item.class}`} style={{ height: "5px" }}>
                                                    <div className="progress-bar" role="progressbar" style={{ width: `${item.progress}` }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div >
                                            </div >
                                        </td >
                                    </tr >)
                                })}
                            </tbody>
                        </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col >
        </Fragment >
    )
}

export default Transaction;