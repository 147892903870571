import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, Media, Label, Input } from 'reactstrap';
import { Addon, AddonHistory, Subscription, SubscriptionHistory } from '../../../../Constant';
import { useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../../Utils/restUtils';
import { getAddonHistory, getSubHistory, viewSingleAddon, viewSingleSubscription, statusSub, statusSubRole, statusAdd, statusAddRole, viewSingleSubscriptionRole, viewSingleAddonRole, getSubHistoryRole, getAddonHistoryRole } from '../../../../CommonUrl';
import { AddonColumns, AddonHistoryColumns, SubHistoryColumns, SubscriptionColumns } from '../../../../Data/MockTable/UserTableHeader';
import { conditionalRow } from '../../../Common/Rowstyle/Rowstyle';

const SubAddonsTabs = (props) => {
    const propsId = props.param;
    const [PrimarycolorLineTab, setPrimarycolorLineTab] = useState('1');
    const authState = useSelector((state) => state.auth);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [addonList, setAddonList] = useState([]);
    const [subHistory, setsubHistory] = useState([])
    const [addonHistory, setAddonHistory] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        subscriptionData();
        subscriptionHistory();
        addonData();
        addonHistoryFn();
    }, []);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };


    const handleSwitchToggle = async (event, subId) => {
        event.preventDefault();
        SweetAlert.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (!result.value) {
                return;
            }
            const reqSwitch = {
                subscription_id: subId,
                status: event.target.checked ? 'off' : 'on'
            };
            const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusSub : statusSubRole, reqSwitch);
            if (statusChange.status === 200) {
                if (statusChange.data.status === true) {
                    event.target.checked = !event.target.checked;
                    SweetAlert.fire('Success', statusChange.data.message, 'success');
                } else {
                    SweetAlert.fire('Error', statusChange.data.message, 'error');
                }
            } else {
                handleApiError(statusChange.status);
            }
        });
    };



    const handleAddonSwitchToggle = async (event, addId) => {
        event.preventDefault();
        SweetAlert.fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then(async (result) => {
            if (!result.value) {
                return;
            }
            const reqSwitch = {
                add_on_id: addId,
                status: event.target.checked ? 'off' : 'on'
            };
            const statusChange = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? statusAdd : statusAddRole, reqSwitch);
            if (statusChange.status === 200) {
                if (statusChange.data.status === true) {
                    event.target.checked = !event.target.checked;
                    SweetAlert.fire('Success', statusChange.data.message, 'success');
                } else {
                    SweetAlert.fire('Error', statusChange.data.message, 'error');
                }
            } else {
                handleApiError(statusChange.status);
            }
        });
    }

    const handlePageChange = page => {
        fetchCustomer(page);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchCustomer(page, newPerPage);
        setPerPage(newPerPage);
    };

    const subscriptionData = async () => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewSingleSubscription + `?customer_id=${propsId}`
            : viewSingleSubscriptionRole + `?customer_id=${propsId}`);
        if (data.status === true) {
            setSubscriptionList(data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    PackName: <span>{e.package_name}</span>,
                    InvoiceNo: e.invoice_number,
                    StartDate: e.start_date,
                    EndDate: e.end_date,
                    RecDate: <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.recurring === "yes" ? true : false}
                                onChange={(event) => handleSwitchToggle(event, e.id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                    PayStatus: e.payment_status,
                    CreateBy: e.created_by_name,
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    Status: e.status,
                    Stb_no: e.stb_no
                }
            }))
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };



    const addonData = async (page, size = perPage) => {
        setLoading(true);   
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewSingleAddon + `?customer_id=${propsId}&page=${page}&page_length=${size}`
            : viewSingleAddonRole + `?customer_id=${propsId}&page=${page}&page_length=${size}`);
        if (data.status === true) {
            setAddonList(data.data.data.map((e, index) => {
                return {
                    Si: index + 1,
                    Name: <span>{e.add_on_name}</span>,
                    PackName: <span>{e.package_name}</span>,
                    InvoiceNo: e.invoice_no,
                    StartDate: e.start_date,
                    EndDate: e.end_date,
                    RecDate: <Media body className={`flex-grow-1 text-end icon-state`}>
                        <Label className="switch">
                            <Input type="checkbox"
                                defaultChecked={e.recurring === "yes" ? true : false}
                                onChange={(event) => handleAddonSwitchToggle(event, e.customer_add_on_id)}
                            />
                            <span className="switch-state" ></span>
                        </Label>
                    </Media>,
                    PayStatus: e.payment_status,
                    CreateBy: e.created_by,
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    Status: e.status,
                    Stb_no: e.stb_no
                }
            }))
            setLoading(false);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const subscriptionHistory = async (page, size = perPage) => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getSubHistory + `?customer_id=${propsId}&page=${page}&page_length=${size}`
            : getSubHistoryRole + `?customer_id=${propsId}&page=${page}&page_length=${size}`);
        if (data.status === true) {
            const subHist_api = data.data;
            setsubHistory(subHist_api.map((e, index) => {
                return {
                    Si: index + 1,
                    Subid: e.customer_subscription_id,
                    Planedate: e.start_date,
                    Enddate: e.end_date,
                    InvoiceNo: e.invoice_no,
                    Status: e.recurring_status === 'no' ? 'off' : 'on',
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    Stb_no: e.stb_no
                }
            }))
            setLoading(false);

        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const addonHistoryFn = async (page, size = perPage) => {
        setLoading(true);
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getAddonHistory + `?customer_id=${propsId}&page=${page}&page_length=${size}`
            : getAddonHistoryRole + `?customer_id=${propsId}&page=${page}&page_length=${size}`);
        if (data.status === true) {
            const subHist_api = data.data;
            setAddonHistory(subHist_api.map((e, index) => {
                return {
                    Si: index + 1,
                    Subid: e.subscriber_id,
                    Planedate: e.start_date,
                    Enddate: e.end_date,
                    InvoiceNo: e.invoice_no,
                    Status: e.recurring_status === 'no' ? 'off' : 'on',
                    order_id: e.order_id,
                    id: e.id,
                    customerid: e.customer_id,
                    Stb_no: e.stb_no
                }

            }))
            setLoading(false);

        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    return (
        <Col sm={12}>
            <Card>
                <CardBody>
                    <Nav className="border-tab nav-primary" tabs>
                        <NavItem>
                            <NavLink className={`${PrimarycolorLineTab === '1' ? 'active' : ''} set-font-style`} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('1')}>{Subscription}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={`${PrimarycolorLineTab === '2' ? 'active' : ''} set-font-style`} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('2')}>{Addon}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={`${PrimarycolorLineTab === '3' ? 'active' : ''} set-font-style`} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('3')}>{SubscriptionHistory}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={`${PrimarycolorLineTab === '4' ? 'active' : ''} set-font-style`} style={{ cursor: 'pointer' }} onClick={() => setPrimarycolorLineTab('4')}>{AddonHistory}</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={PrimarycolorLineTab} >
                        <TabPane className="fade show" tabId="1">
                            <div className="order-history table-responsive" style={{ marginTop: '-15px', height: '200px', overflowY: 'auto' }}>
                                <DataTable
                                    data={subscriptionList}
                                    columns={SubscriptionColumns}
                                    pagination
                                    progressPending={loading}
                                    paginationServer
                                    defaultSortAsc
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    conditionalRowStyles={conditionalRow}
                                    onChangePage={handlePageChange}
                                    style={{ border: '1px solid' }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div className="order-history table-responsive" style={{ marginTop: '-15px', height: '200px', overflowY: 'auto' }}>
                                <DataTable
                                    data={addonList}
                                    columns={AddonColumns}
                                    pagination
                                    progressPending={loading}
                                    paginationServer
                                    defaultSortAsc
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    conditionalRowStyles={conditionalRow}
                                    onChangePage={handlePageChange}

                                    style={{ border: '1px solid' }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <div className="order-history table-responsive" style={{ marginTop: '-15px', height: '200px', overflowY: 'auto' }}>
                                <DataTable
                                    data={subHistory}
                                    columns={SubHistoryColumns}
                                    pagination
                                    progressPending={loading}
                                    paginationServer
                                    defaultSortAsc
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    conditionalRowStyles={conditionalRow}
                                    onChangePage={handlePageChange}
                                    style={{ border: '1px solid' }}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <div className="order-history table-responsive" style={{ marginTop: '-15px', height: '200px', overflowY: 'auto' }}>
                                <DataTable
                                    data={addonHistory}
                                    columns={AddonHistoryColumns}
                                    pagination
                                    progressPending={loading}
                                    paginationServer
                                    defaultSortAsc
                                    paginationTotalRows={totalRows}
                                    paginationDefaultPage={currentPage}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    conditionalRowStyles={conditionalRow}
                                    onChangePage={handlePageChange}

                                    style={{ border: '1px solid' }}
                                />
                            </div>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Col>
    );
};

export default SubAddonsTabs;