import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { EditPackage, Category, Description, DurationInDays, Image, Name, NameError, Package, Price, PurchasePrice, Save, Tax, hsn_sac, subscription_limit, ConnectionType, mrp, setup_cost, SubscriptionError, MrpError, PriceErrors, SetupCostError, PurchasePriceError, DurationError, HSNError, FeatureError, DescriptionError } from "../../../Constant";
import { viewPackage, getcategoryitems, updatePackage, statusTax, viewPackageRole, statusTaxRole, updatePackageRole, getcategoryitemsRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    price: yup.string().required().label('Price'),
    purchase_price: yup.string().required().label('Purchase Price'),
    connectiontype: yup.mixed().required().label('Connection type'),
    duration: yup.string().required().label('Duration In Days'),
    category: yup.mixed().required().label('Category'),
})

const PackagesEdit = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)

    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(schema),
    });
    const location = useLocation();
    const authState = useSelector((state) => state.auth);
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const [image, setImage] = useState(null);
    const [apiImage, setapiImage] = useState(null)
    const [loading, setLoading] = useState(false);
    const [categoryOption, setCategoryOption] = useState([]);
    const [taxOption, setTaxOption] = useState([]);
    const navigate = useNavigate();
    const form = new FormData()

    const connectionOptions = [
        { value: "cable", label: "Cable" },
        { value: "broadband", label: "Broadband" },
        { value: "ip_tv", label: "IPTV" },
        { value: "ott", label: "OTT" },
        { value: "other", label: "Others" },

    ];

    useEffect(() => {
        categoryData();
    }, []);

    const categoryData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewPackage + `?package_id=${param1}` : viewPackageRole + `?package_id=${param1}`);
        if (data.status === true) {
            const single_Api = data.data[0];
            setValue('id', single_Api.id);
            setValue('hsn_no', single_Api.hsn_no);
            setValue('name', single_Api.name);
            setValue('subscription_limit', single_Api.subscription_limit);
            setValue('features', single_Api.features);
            setValue('price', single_Api.price);
            setValue('mrp', single_Api.mrp);
            setValue('purchase_price', single_Api.purchase_price);
            setValue('setup_cost', { label: single_Api.setup_cost });
            setValue('connection_type', { value: single_Api.connection_type, label: single_Api.connection_type })
            setValue("tax", { value: single_Api.tax_group_id, label: single_Api.tax_group_id });
            setValue('duration', single_Api.duration)
            setapiImage(single_Api.package_image)
            setValue('description', single_Api.description)


            const connection_type = connectionOptions.find((e) => e.value === single_Api.connection_type);
            setValue("connectiontype", { value: connection_type?.value, label: connection_type?.label })

            let taxData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
            if (taxData.status === true) {
                const optionList = taxData.data.map((data) => ({ value: data.id, label: data.title }))
                setTaxOption(optionList);
                const same_id = optionList.find((set_id) => set_id.value === single_Api.tax_group_id);
                setValue("tax", { value: same_id?.value, label: same_id?.label })

            } else if (taxData.status >= 400 && taxData.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (taxData.status >= 500 && taxData.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            }

            let dataCategory = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryitems : getcategoryitemsRole);
            if (dataCategory.status === true) {

                const optionsList = dataCategory.data.map((data) => ({ value: data.id, label: data.name }));
                setCategoryOption(optionsList);

                const equal_id = optionsList.find((set_id) => set_id.value === single_Api.category_id);
                setValue("category", { value: equal_id?.value, label: equal_id?.label })
            } else if (dataCategory.status >= 400 && dataCategory.status <= 405) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
            } else if (dataCategory.status >= 500 && dataCategory.status <= 505) {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
            }
            else {
                navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

            }

        } else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false);

    }
    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setImage(selectedFile);
    }

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');
        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);

    const onSubmit = async (data) => {
        setLoading(true);
        form.append("package_id", param1)
        form.append("hsn_no", data.hsn_no ?? '')
        form.append("name", data.name)
        form.append("price", data.price)
        form.append("purchase_price", data.purchase_price)
        form.append("description", data.description)
        form.append("duration", data.duration)
        form.append("tax_group_id", data.tax.value)
        form.append("connection_type", data.connectiontype.value)
        form.append("category_id", data.category.value)
        if (data.image[0]) {
            form.append("image", data.image[0])
        }
        setLoading(true);
        const editPackageResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updatePackage : updatePackageRole, form, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        });
        if (editPackageResponse.status === 200) {
            if (editPackageResponse.data.status === true) {
                toast.success(editPackageResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Services/packages`);
            } else {
                toast.error(editPackageResponse.data.message)
            }
        } else if (editPackageResponse.status >= 400 && editPackageResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (editPackageResponse.status >= 500 && editPackageResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}
            {spinnerTrans && <Transparentspinner />}

            <Breadcrumbs mainTitle={Package} parent="Settings" title={Package} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditPackage}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <span className="text-danger">{errors.name && NameError}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="hsn_no">{hsn_sac}</Label>
                                <input className="form-control" id="hsn_no" type="number" min="0"{...register('hsn_no', { required: true })} />
                                <span className="text-danger">{errors.hsn_no && HSNError}</span>
                            </Col>

                            <Col md='6'>
                                <Label className="form-label" for="price">{Price} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="price" type="number" min="0" {...register('price', { required: true })} />
                                <span className="text-danger">{errors.price && PriceErrors}</span>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="purchase_price">{PurchasePrice} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="purchase_price" type="number" min="0"{...register('purchase_price', { required: true })} />
                                <span className="text-danger">{errors.purchase_price && PurchasePriceError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">

                            <Col md="6">
                                <Label className="form-label" >{Tax}</Label>
                                <Controller
                                    name="tax"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">
                                    {errors.industry && IndustryError}
                                </span>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{Category} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={categoryOption}
                                            getOptionValue={(option) => option.value}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <span className="text-danger">{errors.industry && IndustryError}</span>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" >{ConnectionType} <span className="requireStar">*</span> </Label>
                                <Controller
                                    name="connectiontype"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={connectionOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.connectiontype?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="duration">{DurationInDays} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="duration" type="text" {...register('duration', { required: true })} />
                                <span className="text-danger">{errors.duration && DurationError}</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6 mt-2">
                                <Label className="form-label">{Image}</Label>
                                <Controller
                                    name="image"
                                    control={control}
                                    render={({ field }) => (
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <Col md="8">
                                                <input
                                                    {...register("image")}
                                                    className="form-control fileInputStyle"
                                                    type="file"
                                                    onChange={handleLogoChange}
                                                    accept=".png, .jpg, .jpeg"
                                                />
                                            </Col>

                                            <Col md='4 '>
                                                <span className="imagestyle">
                                                    {image == null ? apiImage && <img src={apiImage} alt="image" width="40px" height="40px" /> : <img src={URL.createObjectURL(image)} alt="image" width="40px" height="40px" />}
                                                </span>
                                            </Col>
                                        </div>
                                    )}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <textarea
                                    name="description"
                                    className="form-control"
                                    {...register('description')}
                                    maxLength={300}
                                    rows="3"

                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card >
        </Fragment >
    )
}
export default PackagesEdit;