import React, { Fragment, useEffect, useState } from 'react';
import { LogOut, Pricing } from '../../Constant';
import { Card, Col, Container, Row } from 'reactstrap';
import HeaderCard from '../../Components/Common/Component/HeaderCard';
import Standards from '../../Components/App/Ecommerce/PricingMemberShip/Standard';
import { postMasterToken } from '../../Utils/restUtils';
import { packageSubscription, postSubscription } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import HandleHomePageError from '../../Components/Common/ErrorImport/SignError';
import { useLocation, useNavigate } from 'react-router';
import Transparentspinner from '../../Components/Common/Loader/Transparentspinner';
import NavSign from '../Sign/NavSign';

const SubscriptionSection = () => {
    const location = useLocation();
    const trial = location.state?.trial;
    const id = location.state.id || {};
    const upgradeText = location.state?.text;
    const authState = useSelector((state) => state.auth);
    const [priceDetails, setPriceDetails] = useState([]);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        packageApi();
    }, []);

    useEffect(() => {
        if (location.state?.fromDashboard) {
            window.onpopstate = () => {
                navigate(`${process.env.PUBLIC_URL}/dashboard/default`, { replace: true });
            }
        } else {
            window.onpopstate = () => {
                navigate("/signin");
            }
        }
    }, []);

    const packageApi = async () => {
        setloading(true)
        const response = await postMasterToken(packageSubscription, { industry_id: authState.industryId });
        if (response.status === 200) {
            setPriceDetails(response.data.data);
        }
        else {
            // navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
            HandleHomePageError(navigate, response.status)
            setloading(false)

        }
        setloading(false)
    }

    return (
        <div>
            {loading && <Transparentspinner />}
            <NavSign showProfile={true} buttonText={LogOut} />
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#eff3f9",
                    height: "100%",
                }}
            >
                <Fragment>
                    {loading && <Transparentspinner />}
                    <Container fluid={true} style={{ marginTop: '50px', marginBottom: '35px' }}>

                        <Card>
                            <HeaderCard title={Pricing} details={priceDetails} />
                            {priceDetails?.packages?.length > 0 &&
                                <Standards details={priceDetails} auth={authState} trialShow={trial} companyId={id} upgradeText={upgradeText} />
                            }
                        </Card>
                    </Container>
                </Fragment>
            </div>
        </div>
    );
};

export default SubscriptionSection;
