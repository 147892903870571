import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row, Input, Media, } from "reactstrap";
import { Btn, H3, Breadcrumbs, H6, H5 } from "../../../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import SpinnerLoader from "../../../../Layout/SpinnerLoader";
import {
  paymentMethod, addInvoicePayment, getCustomers, paymentformtype, paymentSelectbox, paymentMethodRole, getCustomersRole,
  paymentformtypeRole, paymentSelectboxRole, addInvoicePaymentRole
} from "../../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken, } from "../../../../Utils/restUtils";
import { toast } from "react-toastify";
import Typeahead from "../../../../CommonElements/TypeAhead";
import {
  Amount, PaymentMethod, DateText, Description, Save, Reference, InvoicePayment, InvoicePaymentAdd, Customer, StbModem,
  totalPending, Invoice, Type, Payfromwallet, Walletbalance, TotalPay,
  CollectAmount
} from "../../../../Constant";
import DatePicker from 'react-datepicker';
import { format } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Select from 'react-select';
import moment from "moment";
import Transparentspinner from "../../../Common/Loader/Transparentspinner";
import SweetAlert from 'sweetalert2';



const schema = yup.object().shape({
  customer: yup.mixed().required().label('Customer'),
  amount: yup.string().required().label('Amount'),
  date: yup.string().required().label('Date'),
});

const InvoicePaymentForm = () => {
  const [spinnerTrans, setspinnerTrans] = useState(true)
  const { register, handleSubmit, control, formState: { errors }, setValue, getValues } = useForm({
    defaultValues: {
      date: format(new Date(), "yyyy-MM-dd"),
    },
    resolver: yupResolver(schema),
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '44px', // Set your desired height here
      minHeight: '44px', // Ensure the minimum height is also set
      borderColor: state.isFocused ? '#534686' : '#d2d2d2', // Change border color on focus
      boxShadow: state.isFocused ? '0 0 0 1px #534686' : provided.boxShadow, // Ensure box shadow matches border color
      '&:hover': {
        borderColor: state.isFocused ? '#534686' : '#d2d2d2' // Change border color on hover when focused
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '44px', // Match the height here as well
      display: 'flex',
      alignItems: 'center',
    }),
    input: (provided) => ({
      ...provided,
      height: '44px', // Ensure the input area height matches
      padding: '0',
      margin: '0',
    }),
  };

  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const searchParams = location.search;
  const params = new URLSearchParams(searchParams);
  const param1 = params.get("customer_id");
  const param2 = params.get("invoice_id");
  const param3 = params.get("invoice_no");
  const param4 = params.get("con_type");
  const param5 = params.get("stb_id");
  const [loading, setLoading] = useState(false);
  const [Payment, setPayment] = useState([]);
  const [startDate, setstartDate] = useState(new Date());
  const [customerId, setCustomerId] = useState(param1);
  const [stbapiOption, setStbapiOption] = useState([]);
  const [invoiceOptions, setinvoceOptions] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [isChecked, setisChecked] = useState(false);
  const [isAmount, setisAmount] = useState('');
  const [decimalWalletbalance, setdecimalWalletbalance] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState("");
  const [invoiceValue, setInvoiceValue] = useState([]);
  const [isAmountDisabled, setIsAmountDisabled] = useState(false);
  const navigate = useNavigate();

  const type = [
    { value: '', label: "Any" },
    { value: "cable", label: "Cable" },
    { value: "broadband", label: "Broadband" },
    { value: "ip_tv", label: "IPTV" },
    { value: "ott", label: "OTT" },
    { value: 'sales_order', label: 'Sales Order' },
    { value: "others", label: "Others" },
  ];


  useEffect(() => {
    paymentData();
    setCustomer();
    selectType(param4);
  }, []);

  useEffect(() => {
    const inputElement = document.querySelectorAll('input[type="number"]');

    const preventScrollEvent = (event) => {
      event.preventDefault();
    };

    inputElement.forEach((inputElement) => {
      inputElement.addEventListener('wheel', preventScrollEvent);

      return () => {
        inputElement.removeEventListener('wheel', preventScrollEvent);
      };
    });
  }, []);

  useEffect(() => {
    if (initialRender) { // Only on initial render
      const findContype = type.find((e) => e.value == param4);
      setValue("type", findContype);
      setInitialRender(false); // Update initial render flag
    }
  }, [param4]);

  const handleChangeDate = date => {
    setstartDate(date);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setisChecked(isChecked);
    const amount_to_pay = getValues("amount");
    if (isChecked) {
      setIsAmountDisabled(true);
      const walt_balnc = getValues("wallet");
      if (parseFloat(isAmount) > parseFloat(walt_balnc)) {
        setValue("amount", walt_balnc);
      } else if (parseFloat(isAmount) <= parseFloat(walt_balnc)) {
        setValue("amount", amount_to_pay);
      }
      setValue("wallet_money", 1);
    } else {
      setValue("amount", isAmount);
        setIsAmountDisabled(false);
      setValue("wallet_money", null);
    }
  }


  const paymentData = async () => {
    const payment = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentMethod : paymentMethodRole);
    if (payment.status === true) {
      const set_payment = payment.data.map((itemss) => ({
        value: itemss.id,
        label: itemss.name,
      }));
      setPayment(set_payment);
    } else {
      handleApiError(payment.status);
    }
  };

  const setCustomer = async () => {
    let cust = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
    if (cust.status === true) {
      const set_Customers = cust.data.map((itemss) => ({
        value: itemss.id,
        label: itemss.name,
      }));
      const defaultcustomer = set_Customers?.find(
        (e) => e.value === Number(param1)
      );
      setValue("customer", defaultcustomer?.label);
    } else {
      handleApiError(cust.status);
    }
  };

  const selectType = async (typeSelected) => {
    setspinnerTrans(true);
    const customer_id = Number(customerId);
    if (customer_id) {
      const invoice_id = param2 ? Number(param2) : '';
      const selected_con = typeSelected ? typeSelected : '';
      let stb_get_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? paymentformtype + `?customer_id=${customer_id}&connection_type=${selected_con}&invoice_no=${invoice_id}` :
        paymentformtypeRole + `?customer_id=${customer_id}&connection_type=${selected_con}&invoice_no=${invoice_id}`);
      if (stb_get_api.status === true) {
        const single_stb = stb_get_api.data.stbs;
        const single_inv = stb_get_api.data.invoices;
        const stbType = single_stb.map((item) => ({
          value: item.id,
          label: item.stb_no,
        }))
        setStbapiOption(stbType);
        if (stbType) {
          const findstb = stbType.find((s) => s.value == param5);
          setValue("stb", findstb)
        }
        const invoiceoptions = single_inv.map((item) => ({
          value: item.id,
          label: item.invoice_number + ' ' + '(' + item.invoice_month + ')',
        }))
        setinvoceOptions(invoiceoptions);
        if (invoiceoptions) {
          const findInv = invoiceoptions.find((e) => e.value == param3);
          setValue("invoice", findInv);
          setInvoiceValue([findInv]);
        }
        if (initialRender) { // Only on initial render
          selectAmountType(param4, param5, [param3]); // Call selectAmountType after obtaining necessary values
          setInitialRender(false); // Update initial render flag
        }
        if (!initialRender) {
          setValue("stb", ''); // Reset STB field
          setValue("invoice", ''); // Reset invoice field
        }
      }
      else {
        handleApiError(stb_get_api.status);
      }
    }
    setspinnerTrans(false);
  }

  const selectAmountType = async (typeValue, stbId, invoiceValue) => {
    try {
      setspinnerTrans(true);
      if (!customerId) return;
      const customer_id = Number(customerId);
      const selected_con = typeValue || '';
      const selected_stb = stbId || '';
      const buildApiUrl = (base, role) => (
        `${base}?customer_id=${customer_id}&connection_type=${selected_con}` +
        (role === 1 ?
          (invoiceValue?.map(id => `&invoice_no[]=${id}`).join('') || '') :
          (selected_stb ? `&stb_id[]=${selected_stb}` : `&stb_id=${selected_stb}`) +
          (invoiceValue?.map(id => `&invoice_no[]=${id}`).join('') || '')
        )
      );
      const apiUrl = buildApiUrl(authState.userRole === 1 ? paymentSelectbox : paymentSelectboxRole, authState.userRole);
      const type_get_api = await getClientUrlDataToken(authState.apiUrl, apiUrl);

      if (type_get_api.status === true) {
        const { total_pending_amount, total_payable, wallet_amount } = type_get_api.data;
        const formatValue = value => value.replace(/,/g, '') || 0;
        const pendingAmount = formatValue(total_pending_amount);
        const totalPayable = formatValue(total_payable);
        const walletBalance = formatValue(wallet_amount);
        setValue("pending", pendingAmount);
        setValue("total", totalPayable);
        setdecimalWalletbalance(walletBalance);
        setValue("wallet", walletBalance);
        const numWalletBalance=parseFloat(walletBalance);
        const numTotal=parseFloat(totalPayable);
        if(isChecked){
          setValue("amount", numWalletBalance < numTotal ? walletBalance : totalPayable);
        }else
        setValue("amount", totalPayable);
        setisAmount(totalPayable);
      } else {
        handleApiError(type_get_api.status);
      }
    } catch (error) {
      console.error('Error in selectAmountType:', error);
    } finally {
      setspinnerTrans(false);
    }
  };

  const handleApiError = (status) => {
    if (status >= 400 && status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (status >= 500 && status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
  };

  const handleSwitchToggle = async (data) => {
    const getAmount = getValues("amount")
    SweetAlert.fire({
      title: `<b>Amount </b>:  ${getAmount} `,
      text: 'Note : Once the payment is processed, it cannot be reversed, and no changes are allowed.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      reverseButtons: true
    }).then(async (result) => {
      if (!result.value) {
        return;
      }

      if (!isChecked && !data.paymentMethod) {
        setPaymentMethodError("Payment method is required"); // Setting error message for payment method
        return;
      }
      setLoading(true);
      if (isChecked || data.paymentMethod) {
        const totalPayable = parseFloat(data.amount.replace(/,/g, ''));
        const isCheckedwallet = getValues("wallet_money")
        const postRequest = {
          customer_id: param1,
          connection_type: data.type.value,
          amount: totalPayable,
          date: moment(startDate),
          payment_method: isChecked ? "" : data.paymentMethod?.value,
          reference: data.reference,
          description: data.description ?? '',
          ...(data.stb && { stb: data.stb.value }),
          ...(data.invoice && { order_id: [data.invoice.value] }),
          wallet_money: isCheckedwallet ? isCheckedwallet : ''
        };
        const addAddonResponse = await postClientUrlWithToken(
          authState.apiUrl, authState.userRole === 1 ? addInvoicePayment : addInvoicePaymentRole, postRequest
        );
        if (addAddonResponse.status === 200) {
          if (addAddonResponse.data.status === true) {
            toast.success(addAddonResponse.data.message);
            const paymentID = addAddonResponse.data.data;
            navigate(`${process.env.PUBLIC_URL}/billing/thermal-print?payment_id=${paymentID}`);
          } else if (Array.isArray(addAddonResponse.data?.message)) {
            const error = addAddonResponse.data?.map((e) => e.message)
            toast.error(error);
            setLoading(false);
          } else {
            toast.error(addAddonResponse.data.message);
            setLoading(false);
          }
        } else {
          handleApiError(addAddonResponse.status);
        }
      }
      setLoading(false);
    })
  };

  const handleInvoiceChange = (value, field) => {
    field.onChange(value);
    setInvoiceValue(value);
    const selectedInvoiceValue = value?.map(option => option.value) || [];
    const typeValue = getValues('type')?.value || '';
    const stbValue = getValues('stb')?.value || '';
    if (value.length !== 0 && !isChecked) {
      setIsAmountDisabled(false);
      selectAmountType(typeValue, stbValue, selectedInvoiceValue);
    } else {
      setIsAmountDisabled(isChecked);
      if (isChecked) {
        const amountToPay = getValues("pending");
        const walletBalance = getValues("wallet");
        setValue('total', amountToPay);
        if (parseFloat(isAmount) > parseFloat(walletBalance)) {
          setValue("amount", walletBalance);
        } else {
          setValue("amount", amountToPay);
        }
      }
      selectAmountType(typeValue, stbValue, selectedInvoiceValue);
    }
  };


  return (
    <Fragment>
      {loading && <SpinnerLoader />}
      {spinnerTrans && <Transparentspinner />}


      <Breadcrumbs mainTitle={InvoicePayment} parent="Billing" title={InvoicePayment} />
      <Form className="needs-validation" noValidate onSubmit={handleSubmit(handleSwitchToggle)} >
        <Card>
          <CardHeader className="pb-0">
            <H3>{InvoicePaymentAdd}</H3>
          </CardHeader>
          <CardBody>
            <Row className="g-3 pt-2">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {Customer} <span className="requireStar">*</span>
                </Label>
                <input className="form-control" id="validationCustom02" type="text"
                  {...register("customer", { required: true })}
                  readOnly
                />
                <p className="text-danger">{errors.name?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{Type}</Label>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Typeahead
                      options={type}
                      onChange={(value) => {
                        selectType(value?.value);
                        selectAmountType(value?.value);
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.type?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 mt-1">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{StbModem}</Label>
                <Controller
                  name="stb"
                  control={control}
                  defaultValue=''
                  render={({ field }) => (
                    <Typeahead
                      placeholder="Select..."
                      options={stbapiOption}
                      onChange={(value) => {
                        const typeValue = getValues('type') ? getValues('type').value : ''; // Get the connection type value
                        selectAmountType(typeValue, value.value);
                        field.onChange(value);
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.stb?.message}</p>
              </Col>
              <Col md="6" className="mt-3">
                <Label className="form-label" for="multiple-typeahead">{Invoice}</Label>
                <Controller
                  name="invoice"
                  control={control}
                  render={({ field }) => (
                    <Select
                      closeMenuOnSelect={true}
                      isMulti
                      styles={customStyles}
                      onChange={(value) => handleInvoiceChange(value, field)}
                      value={field.value}
                      options={invoiceOptions}
                    />
                  )}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{totalPending}</Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  disabled
                  type="number"
                  {...register("pending", { required: true })}
                />
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {TotalPay}
                </Label>
                <input
                  className="form-control"
                  id="validationCustom02"
                  disabled
                  type="number"
                  {...register("total", { required: true })}
                />
              </Col>
            </Row>

          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" for="validationCustom02">
                  {CollectAmount}<span className="requireStar">*</span>
                </Label>
                <Controller
                  name="amount"
                  control={control}
                  render={({ field }) => (
                    <input className="form-control" id="amount" type="number" min="0"
                      disabled={isAmountDisabled}
                      onChange={(value) => {
                        field.onChange(value);
                        const e = value.target.value;
                        setisAmount(e)
                      }}
                      value={field.value}
                    />
                  )}
                />
                <p className="text-danger">{errors.amount?.message}</p>
              </Col>
              {isAmount > 0 && decimalWalletbalance > 0 && (
                <Col md="6" className="">
                  <Card>
                    <Media className="d-flex p-20">
                      <div className="form-check me-3">
                        <Input className="wallet-checkbox" id="checkbox-primary-1" type="checkbox" checked={isChecked} onChange={handleCheckboxChange} style={{ transform: 'scale(1.7)' }} />
                        <Label for="checkbox-primary-1"></Label>
                      </div>
                      <Media body className='flex-grow-1'>
                        <H3 attrH6={{ className: "mt-0 mega-title-badge" }}>{Walletbalance}<span className="badge badge-primary pull-right digits">{authState.currency} {decimalWalletbalance}</span></H3>
                        <H5>{Payfromwallet}</H5>
                      </Media>
                    </Media>
                  </Card>
                </Col>
              )}
              {isChecked === false &&
                <Col md="6">
                  <Label className="form-label" for="validationCustom02">
                    {PaymentMethod}<span className="requireStar">*</span>
                  </Label>
                  <Controller
                    name="paymentMethod"
                    control={control}
                    render={({ field }) => (
                      <Typeahead
                        options={Payment}
                        onChange={(value) => {
                          field.onChange(value);
                          setPaymentMethodError("");
                        }}
                        value={field.value}
                      />
                    )}
                  />
                  {paymentMethodError && <p className="text-danger">{paymentMethodError}</p>} {/* Display error message */}
                </Col>
              }
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" for="validationCustom02">{DateText}</Label>
                  <Col xl='12' md="12" sm='12'>
                    <DatePicker
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      selected={startDate}
                      onChange={handleChangeDate}
                      required
                    />

                  </Col>
                </FormGroup>
              </Col>
              <Col md="6">
                <Label className="form-label" for="validationCustom02">{Reference}</Label>
                <input className="form-control" id="validationCustom02" type="text"
                  {...register("reference", { required: true })}
                />
                <p className="text-danger">{errors.name?.message}</p>
              </Col>
            </Row>
            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Description}</Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" />
                  )}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
          </CardBody>
        </Card>
      </Form>
    </Fragment>
  );
};
export default InvoicePaymentForm;
