import React, { useEffect, useState } from 'react'
import { Breadcrumbs, Btn, H3, H4 } from '../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { deleteCustomerDueImportedLog, deleteCustomerImportedLog, deleteWalletDueImportedLog, getCustomerDueImportedLog, getCustomerImportedLog, getWalletDueImportedLog } from '../../../CommonUrl';
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Search } from '../../../Constant';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const WalletLogList = () => {
  const authState = useSelector((state) => state.auth);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [walletList, setWalletList] = useState([]);
  const [spinnerTrans, setspinnerTrans] = useState(false);

  const WalletColumns = [
    {
      name: <H4>Sl</H4>,
      selector: row => row.Si,
      sortable: true,
      width: '50px',
    },
    {
      name: <H4>Comment</H4>,
      selector: row => row.Comment,
      sortable: true,
      wrap: true,
    },
    {
      name: <H4>Subscriber Id</H4>,
      selector: row => row.Sub,
      sortable: true,
      wrap: true,
    },
    {
      name: <H4>Amount</H4>,
      selector: row => row.Amount,
      sortable: true,
      wrap: true,
    },
  ];

  useEffect(() => {
    importWalletlog();
  }, [searchKeyword]) 

  const importWalletlog = async () => {
    setspinnerTrans(true)
    const response = await getClientUrlDataToken(authState.apiUrl, getWalletDueImportedLog);
    if (response.status == true) {
      setWalletList(response.data.data.map((e, index) => {
        return {
          Si: index + 1,
          Sub: <span>{e.subscriber_id}</span>,
          Amount: e.amount,
          Comment: e.comments?.replace(/^\["|"]$/g, ''),
        }
      }))
    } else {
      setspinnerTrans(false)
      toast.success("Something went wrong while fetching data");
    }
    setspinnerTrans(false)
  }

  const handleRemoveImpoted = async () => {
    setspinnerTrans(true)
    const deleteLogList = await postClientUrlWithToken(authState.apiUrl, deleteWalletDueImportedLog);
    if (deleteLogList.status === 200) {
      if (deleteLogList.data.status == true) {
        toast.success(deleteLogList.data.message);
        importWalletlog();
      } else {
        toast.error(deleteLogList.data.message);
        setspinnerTrans(false)
      }
    } else if (deleteLogList.status >= 400 && deleteLogList.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (deleteLogList.status >= 500 && deleteLogList.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setspinnerTrans(false)
  }

  const handleSearchInputChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  return (
    <>
      {spinnerTrans && <Transparentspinner />}
      <Breadcrumbs mainTitle="Wallet Error Log" parent="Customers / Wallet" title="Wallet error log" />
      <Card className='cardWidth'>
        <CardHeader style={{ margin: '-5px 0 -20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <H3>Wallet Error Log</H3>
          <Btn attrBtn={{ color: 'danger', onClick: () => handleRemoveImpoted() }} ><i className="fa fa-trash-o "></i> Clear Log </Btn>
        </CardHeader>
        <CardBody>
          <span className='custom-span'>
            <Row className="ps-3 mb-4 custom-search-filter align-items-center">
              <Col xs="6" sm="4" md="2" lg="2">
                <input className="form-control" placeholder={Search}  type="text" value={searchKeyword} onChange={handleSearchInputChange}
                />
              </Col>
            </Row>
          </span>
          <div className="order-history table-responsive">
            <DataTable
              columns={WalletColumns}
              data={walletList}
              defaultSortAsc
              fixedHeader
              pagination
            />
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default WalletLogList