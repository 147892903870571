import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import DataTable from 'react-data-table-component';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { H3, Btn, Breadcrumbs, H4 } from '../../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { Wallet, WalletList, Search, Filter, Account, Export, Import, walletImportTitle, walletImportTableTitle, importFile } from '../../../Constant';
import { ViewWallet, ViewWalletRole, getCustomers, getCustomersRole, postWalletImport, publicSampleDownloadWallet, viewAccount, viewAccountRole } from '../../../CommonUrl';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Transparentspinner from '../../Common/Loader/Transparentspinner';
import Typeahead from '../../../CommonElements/TypeAhead';
import PopupImport from '../../Common/Popup/popupImport';
import ImportWallet from '../../Common/Import/ImportWallet';

const WalletListContainer = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true);
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [filters, setFilters] = useState({
        search: '',
        start: '',
        end: '',
        customerId: '',
        account_id: ''
    });
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([null, null]);
    const [currentPage, setCurrentPage] = useState(1);
    const [apiCustomer, setapiCustomer] = useState([]);
    const [customerOptions, setCustomerOptions] = useState([]);
    const [apiAccount, setapiAccount] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [startDate, endDate] = dateRange;
    const searchstb = useRef(null)
    const [isCustomer, setIsCustomer] = useState('');
    const [importDrpodown, setimportDrpodown] = useState(false);
    const [modalImport, setModalImport] = useState(false);
    const [sortConfig, setSortConfig] = useState({ column: '', order: '' });
    const isAccount = useRef(null);
    const fileImport = <span className='billerqColor bolder '>
        <h3>{importFile}</h3>
    </span>

    useEffect(() => {
        fetchWallet(1);
        customerData();
        accountData();
    }, []);

    useEffect(() => {
        const customerOptions = apiCustomer.map((customer) => ({
            value: customer.id,
            label: customer.name,
        }));
        setCustomerOptions(customerOptions);
    }, [apiCustomer]);

    const WalletColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.si,
            width: '80px',
        },
        {
            name: <H4>Subscriber Id</H4>,
            id: 'subscriber_id',
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.CustomerId}`}>
                        {row.Sub}
                    </Link> : row.Sub,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Customer</H4>,
            id: 'name',
            selector: row => row.Customer,
            sortable: true,
            wrap: true,
        },
        {
            name: <H4>Amount</H4>,
            id: 'amount',
            selector: row => row.Amount,
            sortable: true,
            center: true,
        },
        {
            name: <H4>Date</H4>,
            id: 'paid_at',
            selector: row => row.Date,
            sortable: true,
            center: true,
        },
        {
            name: <H4>Transaction Id</H4>,
            id: 'transaction_id',
            selector: row => row.Transaction,
            sortable: true,
            center: true,
        },
        {
            name: <H4>Reference</H4>,
            id: 'reference',
            selector: row => row.Ref,
            sortable: true,
            center: true,
        },
        {
            name: <H4>Account</H4>,
            id: 'account_name',
            selector: row => row.Account,
            sortable: true,
            Wrap: true,
            center: true,
        },
    ];

    const handleSort = (column) => {
        let newOrder = 'asc';
        if (sortConfig.column === column.id) {
            newOrder = sortConfig.order === 'asc' ? 'desc' : 'asc';
        }
        setSortConfig({ column: column.id, order: newOrder });
        fetchWallet(1, perPage, '', '', '', '', '', column.id, newOrder);
    };

    const handleChange = (selectedOption) => {
        setIsCustomer(selectedOption);
    };

    const toggleImport = () => setimportDrpodown(!importDrpodown);

    const handleModalImport = () => {
        setModalImport(!modalImport);
    }

    const handleImportLog = () => {
        const navigatetoLog = `${process.env.PUBLIC_URL}/customers/wallet/wallet-log-list`;
        navigate(navigatetoLog)
    }

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };


    const fetchWallet = async (page, size = perPage, searchKey = '', start_date = '', end_date = '', customerId = '', accountId = '', sortBy = '', sortOrder = '') => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? ViewWallet +
            `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&customer_id=${customerId}&account_id=${accountId}&sort_by=${sortBy}&sort_order=${sortOrder}` : ViewWalletRole +
        `?page=${page}&page_length=${size}&search=${searchKey}&start_date=${start_date}&end_date=${end_date}&customer_id=${customerId}&account_id=${accountId}&sort_by=${sortBy}&sort_order=${sortOrder}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(response?.data?.data.map((e, index) => {
                return {
                    si: SerialNo + index,
                    Ref: e.reference,
                    Sub: e.subscriber_id,
                    Date: new Date(e.paid_at).toLocaleDateString("en-GB"),
                    Amount: `${authState.currency} ${e.amount}`,
                    Customer: e.customer_name,
                    Transaction: e.transaction_id,
                    Description: e.description,
                    Account: e.account_name,
                    CustomerId: e.customer_id,
                }
            }))
            setTotalRows(response.data.total);
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const exportWalletExcel = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            ViewWallet + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : ViewWalletRole + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ customer_id, paid_at, amount, customer_name, description, account_name }) => ({ TXN_Id: customer_id, Date: paid_at, Amount: authState.currency + amount, Customer: customer_name, Description: description, Account: account_name }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Wallet Data');
            XLSX.writeFile(wb, 'wallet_data.xlsx');
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const exportWalletPdf = async () => {
        setspinnerTrans(true)
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ?
            ViewWallet + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`
            : ViewWalletRole + `?export=wallet_data&search=${filters?.search ? filters.search : ''}
            &start_date=${filters?.start ? filters.start : ''}&end_date=${filters?.end ? filters.end : ''}
            &customer_id=${filters?.customerId ? filters.customerId : ''}
            &status=${filters?.account_id ? filters.account_id : ''}&sort_by=${sortConfig.column}&sort_order=${sortConfig.order}`);
        const dataToExport = response.data.map(({ customer_id, paid_at, amount, customer_name, description, account_name }, index) => ({ Sl: index + 1, customer_id, paid_at, amount, customer_name, description, account_name }));

        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "TXN Id", "Date", "Amount", "Customer", "Description", "Account",];
            const rows = dataToExport.map(({ customer_id, paid_at, amount, customer_name, description, account_name }, index) => [index + 1, customer_id, paid_at, amount, customer_name, description, account_name]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("wallet_data.pdf");
        } else {
            handleApiError(response.status);
        }
        setspinnerTrans(false)
    };

    const handlePageChange = page => {
        fetchWallet(page, perPage, filters.search, filters.start, filters.end, filters.customerId, filters.account_id, sortConfig.column, sortConfig.order);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchWallet(page, newPerPage, filters.search, filters.start, filters.end, filters.customerId, filters.account_id, sortConfig.column, sortConfig.order);
        setPerPage(newPerPage);
    };

    const customerData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCustomers : getCustomersRole);
        if (data.status === true) {
            setapiCustomer(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const accountData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAccount : viewAccountRole);
        if (data.status === true) {
            setapiAccount(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const handleFilter = async (e) => {
        e.preventDefault()
        const search = searchstb.current.value;
        const account_id = isAccount.current.value;
        const customer_id = isCustomer?.value;
        const start = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            customer_id,
            account_id,
            start,
            end,
            account_id
        });
        fetchWallet(1, perPage, search, start, end, customer_id, account_id);
    };


    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <Breadcrumbs mainTitle={Wallet} parent="Customers" title={Wallet} />
            <Card>
                <CardHeader style={{ margin: '-25px 0 -40px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{WalletList}</H3>
                    <div>
                        <CardBody className="dropdown-basic">
                            <Dropdown className='ms-2' isOpen={importDrpodown} toggle={toggleImport} style={{ marginRight: '10px' }}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Import} <i className="icofont icofont-arrow-down"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => handleModalImport()}><i className="fa fa-file-text-o me-2"></i> Import Wallet</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => handleImportLog()}><i className="fa fa-list-alt me-2"></i> Imported Wallet Logs</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                    {Export} <i className="icofont icofont-arrow-up"></i>
                                </DropdownToggle>
                                <DropdownMenu container="body">
                                    <DropdownItem style={toggleStyle} onClick={() => exportWalletExcel()}> <i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                    <DropdownItem style={toggleStyle} onClick={() => exportWalletPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </CardBody>
                    </div>
                </CardHeader>
                <CardBody>
                    <span className=''>
                        <Form className="needs-validation" noValidate onSubmit={handleFilter}>
                            <Row className="ps-3 gap-sm-3 gap-md-2 mb-4">
                                <Col sm="12" md='5' lg='2' className="form-element">
                                    <input className="form-control" placeholder={Search} type="text"
                                        ref={searchstb} />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <div style={{ position: 'relative', zIndex: 2 }}>
                                        <DatePicker
                                            className="form-control digits w-100"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update);
                                            }}
                                            isClearable={true}
                                            monthsShown={2}
                                            popperPlacement="bottom"
                                            calendarClassName='parallel-calendar'
                                            placeholderText='Select Date Range'

                                        />
                                    </div>

                                </Col>
                                <Col sm="12" md="5" lg="2" className="form-element">
                                    <Typeahead
                                        options={customerOptions}
                                        onChange={handleChange}
                                        placeholder="Customer..."
                                    />
                                </Col>
                                <Col sm="12" md="5" lg='2' className="form-element">
                                    <select className="form-select" ref={isAccount}>
                                        <option value="">{Account}</option>
                                        {apiAccount.map((values, index) => (
                                            <option key={index} value={values.id}>{values.name}</option>
                                        ))}
                                    </select>
                                </Col>
                                <Col sm="12" md="5" lg='1' className="custom-filtrs">
                                    <Btn attrBtn={{ color: "primary", }} type="submit">{Filter}</Btn>
                                </Col>
                            </Row>
                        </Form>
                    </span>

                    <div className="order-history table-responsive">
                        <DataTable
                            columns={WalletColumns}
                            data={customerLists}
                            onSort={handleSort} // Attach sorting handler here
                            sortServer // Enable server-side sorting
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
            <PopupImport isOpen={modalImport} title={fileImport} toggler={handleModalImport} >
                <ImportWallet
                    setModal={setModalImport}
                    sampleImportApi={publicSampleDownloadWallet}
                    postImportApi={postWalletImport}
                    imporTitle={walletImportTitle}
                    tableTitle={walletImportTableTitle}
                    tablerenderFunc={fetchWallet}
                    currentPage={currentPage}
                    perPage={perPage}
                />
            </PopupImport>
        </Fragment>
    );
};

export default WalletListContainer;