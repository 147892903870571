import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { PaymentDueRep, PaymentDueList, Search, Filter, Category, Area, Status, Export, } from '../../Constant';
import { getArea, getAreaRole, getCategoryRole, getCollectionDue, getCollectionDueRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { conditionalRow } from '../Common/Rowstyle/Rowstyle';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const PaymentDueReport = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [apiArea, setapiArea] = useState([]);
    const [category, setcategory] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filters, setFilters] = useState({
        searchKey: '',
        start_date: '',
        end_date: '',
        category: '',
        areaFilter: '',
        statusFilter: '',
    });
    const statuscheck = [
        { value: "active", name: "Active" },
        { value: "inactive", name: "Inactive" }
    ];

    const PaymentDueColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            center: false,
            width: '55px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            center: true,
            width: '180px'
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            center: false,
            width: '120px',
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}>
                    {row.Status.charAt(0).toUpperCase() + row.Status.slice(1)}
                </span>
            ),
        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true,
            width: '300px'
        },
        {
            name: <H4>Area</H4>,
            selector: row => row.Area,
            sortable: true,
            center: false,
        },
        {
            name: <H4>Balance Due</H4>,
            selector: row => row.Balance,
            sortable: true,
            center: false,
            width: '120px'
        },
        {
            name: <H4>No.of Invoices</H4>,
            selector: row => row.InvoiceNo,
            sortable: true,
            center: true,
        }
    ];

    useEffect(() => {
        fetchPayDue(1);
        areaData();
        categoryapi();
    }, []);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handlePageChange = page => {
        fetchPayDue(page, perPage, filters.search, filters.start_date, filters.end_date, filters.category, filters.areaId, filters.statusFilter);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchPayDue(page, newPerPage, filters.search, filters.start_date, filters.end_date, filters.category, filters.areaId, filters.statusFilter);
        setPerPage(newPerPage);
    };
    const areaData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const categoryapi = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, getCategoryRole);
        if (data.status === true) {
            setcategory(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const onSubmit = async (data) => {
        const areaId = data.area;
        const categoryId = data.category;
        const status = data.status;
        const search = data.search;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            start_date,
            end_date,
            categoryId,
            areaId,
            status
        });
        fetchPayDue(currentPage, perPage, search, start_date, end_date, categoryId, areaId, status);
    };

    const fetchPayDue = async (page, size = perPage, search = "", startDate = '', endDate = '', categoryId = '', areaId = '', status = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionDue +
            `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}&category_id=${categoryId}&status=${status}` :
            getCollectionDueRole + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}&category_id=${categoryId}&status=${status}`)
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const valuePackages = response.data.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valuePackages).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    SubId: valuePackages[e].subscriber_id,
                    Name: valuePackages[e].customer_name,
                    Address: valuePackages[e].customer_address,
                    Area: valuePackages[e].area_name,
                    Balance: <span style={{ fontWeight: 'bold', color: '#534686' }}>{authState.currency} {valuePackages[e].order_balance}</span>,
                    InvoiceNo: valuePackages[e].invoice_count,
                    Status: valuePackages[e].customer_status,
                    customer_id: valuePackages[e].customer_id,
                }
            }
            ))
            setTotalRows(response.data.total);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportDueExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionDue +
            `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&area_id=${filters?.areaFilter ?? ''}&category_id=${filters?.category ?? ''}&status=${filters?.statusFilter ?? ''}` :
            getCollectionDueRole + `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&area_id=${filters?.areaFilter ?? ''}&category_id=${filters?.category ?? ''}&status=${filters?.statusFilter ?? ''}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }) => ({ Subscriber_id: subscriber_id, Status: customer_status, Name: customer_name, Route_No: customer_address, Area: area_name, Balance: authState.currency + order_balance, Number_of_Invoice: invoice_count }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Collection Due Data');
            XLSX.writeFile(wb, 'collection_due_data.xlsx');
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportDuePdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getCollectionDue +
            `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&area_id=${filters?.areaFilter ?? ''}&category_id=${filters?.category ?? ''}&status=${filters?.statusFilter ?? ''}` :
            getCollectionDueRole + `?export=collection_due_data&search=${filters?.searchKey ? filters.searchKey : ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&area_id=${filters?.areaFilter ?? ''}&category_id=${filters?.category ?? ''}&status=${filters?.statusFilter ?? ''}`);
        const dataToExport = response.data.map(({ subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }, index) => ({ Sl: index + 1, subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber id", "Status", "Name", "Route No", "Area", "Balance", "Number of Invoice"];
            const rows = dataToExport.map(({ subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count }, index) => [index + 1, subscriber_id, customer_status, customer_name, customer_address, area_name, order_balance, invoice_count]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("collection_due_data.pdf");
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };


    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Row>
                <Breadcrumbs mainTitle={PaymentDueRep} parent="Reports" title={PaymentDueRep} />
            </Row>
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{PaymentDueList}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportDueExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportDuePdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'

                                />
                            </div>

                        </Col>

                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('category')}>
                                <option value="">{Category}</option>
                                {category.map((c, index) => (
                                    <option key={index} value={c.id}>{c.name}</option>
                                ))}
                            </select>
                        </Col>

                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('area')}>
                                <option value="">{Area}</option>
                                {apiArea.map((values, index) => (

                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"   {...register('status')}>
                                <option value="">{Status}</option>
                                {statuscheck.map((c, index) => (
                                    <option key={index} value={c.value}>{c.name}</option>

                                ))}
                            </select>
                        </Col>

                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={PaymentDueColumns}
                            data={customerLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[ 10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default PaymentDueReport;