import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { getpaymentId, getpaymentIdRole } from '../../../CommonUrl';
import { useSelector } from 'react-redux';
import { getClientUrlDataToken } from '../../../Utils/restUtils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Transparentspinner from '../../Common/Loader/Transparentspinner';

const RenewSub = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)

    const authState = useSelector((state) => state.auth);
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("payment_id");
    const [apidata, setapidata] = useState('')
    const navigate = useNavigate();
    const [invoice, setinvoice] = useState(null)
    const [discription, setdiscription] = useState(null)
    const [reference, setreference] = useState(null)

    useEffect(() => {
        handlePayment();
    }, []);

    useEffect(() => {
        if (apidata) {
            handlePrint()
        }
    }, [apidata])

    const componentRef = useRef(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePayment = async () => {
        const get_api = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getpaymentId + `?payment_id=${param1}` : getpaymentIdRole + `?payment_id=${param1}`);
        if (get_api.status === true) {
            setapidata(get_api.payment);
            const invoices_map = get_api.payment.invoices_reflected.map((i) => i).join(', ')
            setinvoice(invoices_map);
            setdiscription(get_api.payment.description);
            setreference(get_api.payment.reference);
        }
        else if (get_api.status >= 400 && get_api.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (get_api.status >= 500 && get_api.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setspinnerTrans(false);
    }

    return (
        <Fragment>
            {spinnerTrans && <Transparentspinner />}
            <style>
                {`
                    @media print {
                        @page {
                            size: A4;
                            margin: 0;
                        }
                        body {
                            margin: 0;
                            padding: 0;
                        }
                        .center-aligned {   
                            width: 25% !important;
                            // margin: auto;
                            text-align:center;
                            margin:12px;
                            width: 25%;
                            text-align: left;
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 20px;
                        }
                        hr{
                            // width:95%;
                            margin-top:-4px !important;
                        }
                        .detail-item  {
                            display: flex;
                            justify-content: space-between;  
                         
                        }
                        .detail-item span {
                            text-align: left;
                        }
                    }
                `}
            </style>

            <div className="print-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                <div className="" style={{ backgroundColor: 'white', padding: '12px', borderRadius: '8px' }}>
                    <div className="receipts  ml-0" style={{ width: '290px', margin: '2px' }} ref={componentRef}>

                        <hr className='hr-line' />
                        <div style={{ textAlign: 'center' }}>
                            <h2>{apidata.company_name}</h2>
                            <h3>+{apidata.company_country_code + " " + apidata.company_mobile}</h3>
                            <hr className='hr-line' style={{ marginTop: '5px' }} />
                            <h4>
                                PAYMENT RECEIPT
                            </h4>
                            <hr className='hr-line' style={{ marginTop: '5px' }} />
                        </div>
                        <h6 className=''>Customer Details:</h6>
                        <div className='customer-add'>
                            <p className='customer-name'>{apidata.customer_name}</p>
                            <div className="customer-address text-wrap" style={{ width: '19rem' }}>
                                <p>{apidata.customer_address}</p>
                            </div>
                        </div>
                        <hr className='hr-line' style={{ marginTop: '10px' }} />
                        <div className="details-container">
                            <div className="detail-item">
                                <p className='left' >Subscriber id# </p>
                                {authState.userRole === 1 || authState.permissions.includes('show-customer-profile') ?
                                    <Link to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${apidata.customer_id}`}>
                                        <span className='right text-decoration-underline'>{apidata.subscriber_id}</span>
                                    </Link> :
                                    apidata.subscriber_id
                                }
                            </div>
                            <div className="detail-item">
                                <p className='left'>Receipt No# </p><span className='right'>{apidata.receipt_no}</span>
                            </div>
                            <div className="detail-item">
                                <p className='left'>Txn id# </p><span className='right'>{apidata.payment}</span>
                            </div>
                            <div className="detail-item">
                                <p className='left'>Transaction Date: </p><span className='right'>{apidata.transaction_date}</span>

                            </div>
                            <div className="detail-item">
                                <p className='left'>Payment Method:  </p>  <span className='right'>{apidata.payment_method_name}</span>
                            </div>
                        </div>
                        <hr className='hr-line' style={{ marginTop: '-1px' }} />
                        <div className="paid-amount">
                            <p className='left'>Paid Amount :   </p><span className='right'>{authState.currency} {apidata.paid_amount}</span>
                        </div>
                        <hr className='hr-line' style={{ marginTop: '-5px' }} />
                        <div className="invoice-details">
                            <div className="detail-item">
                                <p className='left'>Invoices reflected: </p> <span className='right'>{invoice}</span>
                            </div>
                            {discription != null && (
                                <div className="detail-item">
                                    <p className='left'>Description :</p><span className='right'>{apidata.description}</span>
                                </div>
                            )}
                            {reference !== null && (
                                <div className="detail-item">
                                    <p className='left'>Ref:   </p><span className='right'></span>
                                </div>
                            )}
                            <div className="detail-item">
                                <p className='left'>Total Dues: </p> <span className='right'>{authState.currency} {apidata.total_dues}</span>
                            </div>
                            <div className="detail-item">
                                <p className='left'>Wallet Money</p><span className='right'>{authState.currency} {apidata.wallet_money}</span>
                            </div>
                        </div>
                        {apidata.next_payment_date !== '' &&
                            <div className="hypen mt-2">
                                <p className='thank-you'>Your next Due/Billing date is on  {apidata.next_payment_date}</p>
                            </div>
                        }
                        <p>Thank you</p>
                        <p className='hyphen' style={{ textAlign: 'center' }}>*******</p>
                    </div>

                    <hr />
                    <div className="button-print text-center" >
                        <button className="print-button " onClick={handlePrint}>Print</button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default RenewSub;
