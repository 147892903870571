import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import SpinnerLoader from "../../../Layout/SpinnerLoader";
import { Addon, Category, ConnectionType, Description, EditAddon, HsnNo, Name, Price, Save, Tax, PurchasePrice, Image, DurationInDays } from "../../../Constant";
import { statusTax, statusTaxRole, updateAddon, viewAddon, viewAddonRole, getcategoryitems, getcategoryitemsRole, updateAddonRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import Typeahead from "../../../CommonElements/TypeAhead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    price: yup.string().required().label('Price'),
    tax: yup.mixed().required().label('Tax'),
    connectiontype: yup.mixed().required().label('Connection type'),
    purchaseprice: yup.string().required().label('Purchase Price'),
    category: yup.mixed().required().label('Category'),
    duration: yup.string().required().label('Duration'),


})

const AddonEdit = () => {
    const [spinnerTrans, setspinnerTrans] = useState(true)

    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const { register, handleSubmit, control, formState: { errors }, setValue } = useForm({ resolver: yupResolver(schema) });
    const authState = useSelector((state) => state.auth);
    const [taxOption, setTaxOption] = useState([]);
    const [logo, setLogo] = useState(null);
    const [categoryOption, setCategoryOption] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [image, setimage] = useState(null)
    const connectionOptions = [
        { value: "cable", label: "Cable" },
        { value: "broadband", label: "Broadband" },
        { value: "ip_tv", label: "IPTV" },
        { value: "ott", label: "OTT" },
        { value: "other", label: "Others" },

    ];
    const form = new FormData()

    useEffect(() => {
        getSingleIdData();
    }, []);

    const getSingleIdData = async () => {
        let apiSingleId;
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAddon + `?add_on_id=${param1}` : viewAddonRole + `?add_on_id=${param1}`);

        if (data.status === true) {
            apiSingleId = data.data[0];
            setValue('id', apiSingleId.id);
            setValue("name", apiSingleId.title);
            setValue('hsn', apiSingleId.hsn_no || '');
            setValue("price", apiSingleId.price);
            setValue("purchaseprice", apiSingleId.purchase_price);
            setValue("tax", { value: apiSingleId.tax_group_id, label: apiSingleId.tax_group_id });
            setValue("category", { value: apiSingleId.category_id, label: apiSingleId.category_name });
            setValue("description", apiSingleId.description || '');
            setValue("duration", apiSingleId.days || '');
            const connection_type = connectionOptions.find((e) => e.value === apiSingleId.connection_type);
            setValue("connectiontype", { value: connection_type?.value, label: connection_type?.label })
            setimage(apiSingleId.add_on_image || '')

        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        let categoryData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryitems : getcategoryitemsRole);
        if (categoryData.status === true) {
            const options = categoryData.data.map(category => ({
                value: category.id,
                label: category.name
            }));
            setCategoryOption(options);
        }


        let taxData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
        if (taxData.status === true) {
            const optionList = taxData.data.map((data) => ({ value: data.id, label: data.title }))
            setTaxOption(optionList);
            const equal_id = optionList.find((set_id) => set_id.value === apiSingleId.tax_group_id);
            setValue("tax", { value: equal_id?.value, label: equal_id?.label })
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setspinnerTrans(false)

    }
    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setLogo(selectedFile);
    }

    useEffect(() => {
        const inputElement = document.querySelectorAll('input[type="number"]');

        const preventScrollEvent = (event) => {
            event.preventDefault();
        };

        inputElement.forEach((inputElement) => {
            inputElement.addEventListener('wheel', preventScrollEvent);

            return () => {
                inputElement.removeEventListener('wheel', preventScrollEvent);
            };
        });
    }, []);



    const onSubmit = async (data) => {
        setLoading(true);
        form.append("add_on_id", param1)
        form.append("title", data.name)
        form.append("hsn_no", data.hsn_no ?? '')
        form.append("price", data.price)
        form.append("tax_group_id", data.tax.value)
        form.append("description", data.description ?? '')
        form.append("purchase_price", data.purchaseprice)
        form.append("category_id", data.category.value)
        form.append("connection_type", data.connectiontype.value)
        form.append("days", data.duration)

        if (data.image) {
            form.append("image", data.image[0])
        }

        const editAddonResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? updateAddon : updateAddonRole, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            }

        });
        if (editAddonResponse.status === 200) {
            if (editAddonResponse.data.status === true) {
                toast.success(editAddonResponse.data.message)
                navigate(`${process.env.PUBLIC_URL}/Services/addon`);
            } else {
                setLoading(false);

                toast.error(editAddonResponse.data.message)

            }
        } else if (editAddonResponse.status >= 400 && editAddonResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (editAddonResponse.status >= 500 && editAddonResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <SpinnerLoader />
            )}
            {spinnerTrans && <Transparentspinner />}

            <Breadcrumbs mainTitle={Addon} parent="Billing" title={Addon} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{EditAddon}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="name">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="name" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="hsn_no">{HsnNo}</Label>
                                <input className="form-control" id="hsn_no" type="number" min={0} {...register('hsn', { required: true })} />
                                <p className="text-danger">{errors.hsn?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="price">{Price} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="price" type="number" min={0} {...register('price', { required: true })} />
                                <p className="text-danger">{errors.price?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="purchaseprice">{PurchasePrice} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="purchaseprice" type="number" min={0} {...register('purchaseprice', { required: true })} />
                                <p className="text-danger">{errors.purchaseprice?.message}</p>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col md="6">
                                <Label className="form-label" >{Tax} <span className="requireStar">*</span></Label>
                                <Controller
                                    name="tax"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={taxOption}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.tax?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" >{ConnectionType} <span className="requireStar">*</span> </Label>
                                <Controller
                                    name="connectiontype"
                                    control={control}
                                    render={({ field }) => (
                                        <Typeahead
                                            options={connectionOptions}
                                            onChange={(value) => {
                                                field.onChange(value);
                                            }}
                                            value={field.value}
                                        />
                                    )}
                                />
                                <p className="text-danger">{errors.connectiontype?.message}</p>
                            </Col>
                            <Row className="pt-3">
                                <Col md="6">
                                    <Label className="form-label" >{Category} <span className="requireStar">*</span></Label>
                                    <Controller
                                        name="category"
                                        control={control}
                                        render={({ field }) => (
                                            <Typeahead
                                                options={categoryOption}
                                                getOptionValue={(option) => option.value}
                                                onChange={(value) => {
                                                    field.onChange(value);
                                                }}
                                                value={field.value}
                                            />
                                        )}
                                    />
                                    <span className="text-danger">{errors.industry && IndustryError}</span>
                                </Col>

                                <Col md="6">
                                    <Label className="form-label" for="duration">{DurationInDays} <span className="requireStar">*</span></Label>
                                    <input className="form-control" id="duration" type="number" min={0} {...register('duration', { required: true })} />
                                    <p className="text-danger">{errors.duration?.message}</p>
                                </Col>
                            </Row>

                            <Row className="pt-3">
                                <Col md="6">
                                    <Label className="form-label">{Image}</Label>
                                    <Controller
                                        name="image"
                                        control={control}
                                        render={({ field }) => (
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Col md="8">
                                                    <input
                                                        {...register("image")}
                                                        className="form-control fileInputStyle"
                                                        type="file"
                                                        onChange={handleLogoChange}
                                                        accept=".png, .jpg, .jpeg"
                                                    />
                                                </Col>
                                                <Col md='4'>
                                                    <span className="imagestyle">
                                                        {logo == null ?
                                                            image && <img src={image} alt="image" width="40px" height="40px" /> :
                                                            <img src={URL.createObjectURL(logo)} alt="image" width="40px" height="40px" />}
                                                    </span>
                                                </Col>
                                            </div>
                                        )}
                                    />
                                    <span className="text-danger">{errors.logo && "logo is required"}</span>
                                </Col>
                            </Row>
                        </Row>
                        <Row className="g-3">
                            <Col>
                                <Label className="form-label pt-3">{Description}</Label>
                                <Controller
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea className="form-control" {...field} rows="3" value={field.value} maxLength={300} />
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AddonEdit; 