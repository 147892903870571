import React, { Fragment, useState } from 'react';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { H5, H3, LI, P, UL, Btn, Image } from '../../../../AbstractElements';
import { AboutM, importFile } from '../../../../Constant';
import userImage from '../../../../assets/images/user-profile/default-users.png';
import { FaPencilAlt } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from '../../../Common/Popup/popup';
import EditProfile from './EditProfile';

const AboutCompany = (props) => {
    const personalDetails = props.details;
    const [isShow, setIsShow] = useState(true);
    const [modal, setModal] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");
    const fileImport = <span className='billerqColor bolder '>
        <h3>{EditProfile}</h3>
    </span>

    const handleModal = () => {
        setModal(!modal);
    }

    const handleEdit = () => {
        setModal(!modal);
    };

    const AboutMeData = [
        {
            id: 1,
            icon: <i className="icofont icofont-id-card icofont-lg"></i>,
            designation: 'Email',
            place: personalDetails.email,
        },
        {
            id: 2,
            icon: <i className="icofont icofont-phone-circle icofont-lg"></i>,
            designation: 'Contact No',
            place: '+' + personalDetails.country_code + personalDetails.phone
        },

    ];
    return (
        <Fragment>
            <Popup isOpen={modal} title="Edit profile" toggler={handleModal}>
                <EditProfile personalinfo={personalDetails} />
            </Popup>
            <Card data-intro="This is your Your detail" className="step4">
                <CardHeader>
                    <H3 attrH3={{ className: 'p-0', style: { display: 'flex', alignItems: 'center' } }} >
                        {AboutM}
                        <FaPencilAlt title="Customer Edit " onClick={() => handleEdit()} style={{ cursor: 'pointer', marginLeft: 'auto', fontSize: '0.8em' }} />
                    </H3>
                </CardHeader>
                <div className={`collapse ${isShow ? 'show' : ''}`}>
                    <CardBody className="post-about">
                        <UL attrUL={{ className: 'simple-list' }}>
                            <div className="d-flex" style={{ marginBottom: '30px' }}>
                                <Image attrImage={{ className: 'img-60 img-fluid m-r-20 rounded-circle img-thumbnail', alt: 'user', src: userImage }} />
                                <div className="media-body flex-grow-1">
                                    <H5 className="d-block">{personalDetails.name}</H5>
                                </div>
                            </div>
                            <div className="row"> {/* Add row class here */}
                                {AboutMeData.map((item) =>
                                    <div key={item.id} className="col-sm-6 col-md-4 col-xl-12" style={{ marginBottom: '10px', marginTop: '10px' }}> {/* Wrap each LI in a col-md-4 */}
                                        <LI>
                                            <div className="icon">{item.icon}</div>
                                            <div>
                                                <H5>{item.designation}</H5>
                                                <P attrPara={{ className: 'text-dark' }}>{item.place}</P>
                                            </div>
                                        </LI>
                                    </div>
                                )}
                            </div>
                        </UL>
                    </CardBody>
                </div>
            </Card>
        </Fragment>
    );
};

export default AboutCompany;
