import React from 'react';
import NavSign from './NavSign';
import { useNavigate } from 'react-router-dom';
import './otpSuccess.scss';
import { BsFillCheckCircleFill } from 'react-icons/bs';

const emailOtpSuccess = () =>{
  const navigate = useNavigate()

  const handleOtpSuccess =async()=>{
    navigate('/SignIn');
  }
  return (
    <div className='otp-main-container'>
      <NavSign />
      <div className="otp-container">
        <div className='otp-background'>
          <BsFillCheckCircleFill color='white' size={50} />
          <div style={{ color: 'white', width: '70%', fontSize: '22px', paddingTop: '20px', paddingBottom: '20px' }}>
            <h3 className='head-text'>Your OTP has been successfully verified.</h3>
          </div>
          <div style={{ color: 'white', width: '60%', paddingBottom: '20px' }}>
            <p>Granting you full access to our services and features. We appreciate you taking the extra step to secure your account through OTP verification.</p>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button  onClick ={handleOtpSuccess} className='button-signup text-link'>Get Started</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default emailOtpSuccess