import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useNavigate } from 'react-router-dom';
import { FormGroup, Input, Label } from 'reactstrap';
import NavSign from './NavSign';
import * as authServices from "../../Utils/authServices";
import './style.scss';
import { getMasterDataToken, postDataWithoutResData } from "../../Utils/restUtils";
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import { Login, getShareApi } from '../../CommonUrl';
import CryptoJS from 'crypto-js';
import { SetUserName, setApiUrl, setUserRole, signIn, setCurrency, setPermissions, setSubscription, setIndustryId, setCompanyName, setRole, setSubscriptionDetails, setDecimal, setSubStatus, SetEmail } from '../../Redux/Reducer';

const SignIn = () => {
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const loginEncrypted = localStorage.getItem("login");
  const lastVisitedPage = localStorage.getItem("lastVisitedPage");
  let login = null;
  const secretKey = 'your-secret-key';
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (loginEncrypted) {
    try {
        const bytes = CryptoJS.AES.decrypt(loginEncrypted, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (decryptedData) {
            login = JSON.parse(decryptedData);
        }
    } catch (e) {
        console.error('Error decrypting or parsing login data', e);
    }
}

const isLoggedIn = login && login.userToken && login.masterSubscription === 'Active';

useEffect(() => {
  if (isLoggedIn) {
    navigate(process.env.PUBLIC_URL+lastVisitedPage);
  }
}, [isLoggedIn, lastVisitedPage, navigate]);

  // Function to check if the input is a phone number
  const isPhoneNumber = (value) => {
    const phoneRegex = /^\d{10,12}$/; // Regex for phone numbers with 10 to 13 digits
    return phoneRegex.test(value);
  }

  const formik = useFormik({
    initialValues: {
      password: "",
      emailOrPhone: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(7, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
      emailOrPhone: Yup.string()
        .required('Required')
        .test('emailOrPhone', 'Invalid email or phone number', (value) => {
          if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            return true; // Valid email format
          }
          if (isPhoneNumber(value)) {
            return true; // Valid phone number format
          }
          return false; // Neither email nor phone number format
        })
    }),
    onSubmit(values) {
      onLogin(values);
    },
  });

  const onLogin = async (data) => {
    setLoading(true);
    const loginResponse = await postDataWithoutResData(Login, {
      email: data.emailOrPhone,
      password: data.password
    });
    setLoading(false);
    if (loginResponse.data.status === true) {
      setLoading(true);
      const { token, client_role, client_role_id, url, company, company_name, email, active_company, industry_type_id, master_subscription, package_name, package_expire_in, user_name, currency_symbol, permissions, master_token, decimal } = loginResponse.data.data;
      const packagetDetails = { packageName: package_name, packageExp: package_expire_in }
      authServices.setUser(token, client_role_id, master_token, master_subscription?.status);
      dispatch(setApiUrl(url));
      dispatch(signIn(company));
      dispatch(setCompanyName(company_name));
      dispatch(setIndustryId(industry_type_id));
      dispatch(setSubStatus(master_subscription))
      dispatch(setSubscription(master_subscription));
      dispatch(setSubscriptionDetails(packagetDetails));
      dispatch(setUserRole(client_role_id));
      dispatch(setRole(client_role));
      dispatch(SetUserName(user_name));
      dispatch(SetEmail(email));
      dispatch(setCurrency(currency_symbol));
      dispatch(setPermissions(permissions));
      dispatch(setDecimal(decimal));
      toast.success(loginResponse.data.message);
      if (client_role_id === 1) {
        if (active_company === 0) {
          navigate('/addCompany');
        } else if (master_subscription?.status === 'none' && master_subscription.company_type === 'multi') {
          navigate('/subscriptionAddition', { state: { id: active_company } });
        } else if (master_subscription?.status === 'none' || (master_subscription?.status === 'Pending' && master_subscription?.plan_type === 'free')) {
          navigate('/subscription', { state: { id: active_company } });
        } else if (master_subscription?.status === 'Pending' && master_subscription?.plan_type === 'paid') {
          let response = await getMasterDataToken(getShareApi + `?order_id=${master_subscription.invoice_id}`);
          if (response.status === true) {
            const toReciept = `${process.env.PUBLIC_URL}/invoice?order_id=${response.data}`;
            navigate(toReciept);
          }
          else {
            HandleHomePageError(navigate, data.status)
          }
        } else {
          navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
        }
      } else if (loginResponse.data?.data?.status === "mobile_not_verified") {
        const mobileNumber = loginResponse.data.data.mobile;
        navigate('/SignUp', { state: { form: { updatedForm: { phone: mobileNumber } } } })
      } else {
        navigate(`${process.env.PUBLIC_URL}/dashboard/default`);
      }
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(loginResponse.data.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <NavSign />
      <div className="container signin-container">
        <div className="row" style={{ paddingTop: '100px' }}>
          <div className="col-lg-6 col-sm-12">
            <div className='text-section'>
              <h1>Welcome back!</h1>
              <p className='sub-text'>Please log in to continue using our services. We're thrilled to have you back with us. Thank you for being a valued member of our community! </p>
              <p className='create-text'>Don’t have an account?</p>
              <Link to={'/SignUp'} className='button-signup'>Sign Up</Link>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className='signin-form-container1 pd-top'>
              <h3 className='signin-text'>Sign In</h3>
              <form onSubmit={formik.handleSubmit}>
                <FormGroup className='form-group-Client'>
                  <Label className="col-form-label">Phone no./Email address</Label>
                  <Input className="form-control"
                    type="text"
                    name="emailOrPhone"
                    onChange={formik.handleChange}
                    value={formik.values.emailOrPhone} />
                  {formik.errors.emailOrPhone && formik.touched.emailOrPhone ? (
                    <div className="text-danger">{formik.errors.emailOrPhone}</div>
                  ) : null}
                </FormGroup>
                <FormGroup className='form-group-Client'>
                  <Label className="col-form-label">Password</Label>
                  <div className='form-input position-relative'>
                    <Input className="form-control"
                      type={togglePassword ? 'text' : 'password'}
                      name="password"
                      onChange={formik.handleChange}
                      value={formik.values.password} />
                    <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                      <span className={togglePassword ? '' : 'show'}></span>
                    </div>
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                  ) : null}
                </FormGroup>
                <FormGroup className="form-group-Client" style={{ display: 'flex', alignItems: 'center' }}>
                  <div className="checkbox">
                    <Input id="checkbox1" type="checkbox" />
                    <Label className="text-muted1" for="checkbox1">Remember password</Label>
                  </div>
                  <Link className="link1 text-decoration-underline" to={`${process.env.PUBLIC_URL}/forget-pwd`}>Forgot password?</Link>
                </FormGroup>
                <div className='login-div' style={{ display: 'flex', justifyContent: 'center' }}>
                  <button type="submit" className='login-btnx button-signup'  >{loading ? <span className="loaderx pt-2 "></span> : 'Login'} </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
