import React, { Suspense, Fragment, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../Layout/Loader";
import Home from "../Billerq/Home";
import LayoutRoutes from "./LayoutRoutes";
import PrivateRoute from "./PrivateRoute";
import { authRoutes } from "./AuthRoutes";
import SignIn from "../Billerq/Sign/SignIn";
import SignUp from "../Billerq/Sign/SignUp";
import PhoneOtpSuccess from "../Billerq/Sign/PhoneOTPSuccess";
import EmailOtpSuccess from "../Billerq/Sign/EmailOtpSuccess";
import PriceSec from "../Billerq/Sign/PriceSec";
import CompanySection from "../Billerq/AddCompany/CompanySection";
import SubscriptionSection from "../Billerq/Subscription/AddSubscription";
import ApplyCoupon from "../Billerq/Subscription/ApplyCoupon";
import BillSuccess from "../Billerq/Subscription/BillSuccess";
import Disclaimers from "../Billerq/Disclaimers";
import TermsCondition from "../Billerq/TermsConditon";
import PrivacyPolicy from "../Billerq/PrivacyPolicy";
import Invoice from "../Components/InvoiceExternal/Invoice";
import CancelAndRefund from "../Billerq/CancelAndRefund";
import ShipAndDelivery from "../Billerq/ShipAndDelivery";
import CompSubscription from "../Billerq/Subscription/CompSubscription";
import Error from "../Billerq/Error/Error";
import Loading from "../Billerq/Sign/Loading";
import PhoneOtp from "../Billerq/Sign/PhoneOTP";


const Routers = () => {
  const location = useLocation();
  useEffect(() => {
    localStorage.setItem('lastVisitedPage', location.pathname);
  }, [location]);
  
  return (
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='signin' element={<SignIn />} />
            <Route path='SignUp' element={<SignUp />} />
            <Route path='phoneOtpSuccess' element={<PhoneOtpSuccess />} />
            <Route path='phoneOtp' element={<PhoneOtp />} />
            <Route path='emailOtpSuccess' element={<EmailOtpSuccess />} />
            <Route path='priceSelect' element={<PriceSec />} />
            <Route path='addCompany' element={<CompanySection />} />
            <Route path='subscription' element={<SubscriptionSection />} />
            <Route path='subscriptionAddition' element={<CompSubscription />} />
            <Route path='applyCoupon' element={<ApplyCoupon />} />
            <Route path='billSuccess' element={<BillSuccess />} />
            <Route path="disclaimer" element={<Disclaimers />} />
            <Route path="terms-conditions" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cancellation-and-refund-policy" element={<CancelAndRefund />} />
            <Route path="shipping-and-delivery-policy" element={<ShipAndDelivery />} />
            <Route path="loading" element={<Loading />} />
            <Route path="error" element={<Error />} />
            {/* {authState.subStatus?.invoice_id!=='' && */}
              <Route path="invoice" element={<Invoice />} />
            {/* } */}
            <Route
              path="/*"
              element={
                <PrivateRoute>
                  <LayoutRoutes />
                </PrivateRoute>
              }
            />

            <Route
              path="/"
              element={
                localStorage.getItem("login") ? (
                  <Navigate to="/dashboard/default" />
                ) : (
                  <Navigate to="/" />
                )
              }
            />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
  );
};

const Router = () => {
  return (
    <BrowserRouter basename="/">
      <Routers />
    </BrowserRouter>
  );
};
export default Router;
