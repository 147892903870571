import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { SubscriptionSummaryRep, SubscriptionSummaryList, Search, Filter, Package, ExpiryDays, Export, Area } from '../../Constant';
import { viewSubscriptionReport, viewSubscriptionReportRole, getPackage, getArea, getPackageRole, getAreaRole } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import Progress from '../Common/Progress';
import { useForm } from 'react-hook-form';
import { MessagesSvg } from '../../Data/Widgets/General/Data';
import DatePicker from 'react-datepicker';
import { conditionalRow } from '../Common/Rowstyle/Rowstyle';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const SubscriptionSummary = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [customerList, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [apiPackage, setApiPackage] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' }
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [StaticWidgetsData, setStaticWidgetsData] = useState([])
    const [apiArea, setapiArea] = useState([]);
    const [filters, setFilters] = useState({
        searchKey: '',
        start_date: '',
        end_date: '',
        package: '',
        areaFilter: '',
        expiry: '',
    });

    const expiryOptions = [
        { value: "today", label: "Today" },
        { value: "1", label: "1 Day" },
        { value: "2", label: "2 Days" },
        { value: "3", label: "3 Days" },
        { value: "4", label: "4 Days" },
        { value: "5", label: "5 Days" },
        { value: "6", label: "6 Days" },
        { value: "7", label: "7 Days" },
        { value: "8", label: "8 Days" },
        { value: "9", label: "9 Days" },
        { value: "10", label: "10 Days" },
        { value: "expired", label: "Expired" },
    ];

    const SubscriptionSummaryColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            center: false,
            width: '50px'
        },
        {
            name: <H4>Subscriber Id</H4>,
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            center: true,
            width: '160px'
        },
        {
            name: <H4>Status</H4>,
            selector: row => row.Status,
            sortable: true,
            center: false,
            width: '80px',
            cell: row => (
                <span
                    className={`badge badge-${row.Status}`}
                >
                    {row.Status ? row.Status.charAt(0).toUpperCase() + row.Status.slice(1) : ''}
                </span>
            ),
        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true,
            width: '120px',
        },
        {
            name: <H4>Phone No.</H4>,
            selector: row => row.Phone,
            sortable: true,
            center: false,
            wrap: true,
            width: '140px',
        },
        {
            name: <H4>Area</H4>,
            selector: row => row.Area,
            sortable: true,
            center: false,
            width: '120px',
            wrap: true,
        },
        {
            name: <H4>Current Package</H4>,
            selector: row => row.CurrentPackage,
            sortable: true,
            center: false,
            wrap: true,
            width: '140px',
        },
        {
            name: <H4>Active Date</H4>,
            selector: row => row.ActiveDate,
            sortable: true,
            center: false,
            wrap: true,
            width: '150px',
        },
        {
            name: <H4>Expire Date</H4>,
            selector: row => row.ExpireDate,
            sortable: true,
            center: false,
            wrap: true,
            width: '140px',
        },
        {
            name: <H4>Days Left</H4>,
            selector: row => row.DaysLeft,
            sortable: true,
            center: true,
            width: '65px',
        },
    ];

    useEffect(() => {
        fetchSubSummary(1);
        packageData();
        areaData()
    }, []);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const handlePageChange = page => {
        fetchSubSummary(page, perPage, filters.searchKey, filters.start_date, filters.end_date, filters.package, filters.areaFilter, filters.expiry);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchSubSummary(page, newPerPage, filters.searchKey, filters.start_date, filters.end_date, filters.package, filters.areaFilter, filters.expiry);
        setPerPage(newPerPage);
    };

    const packageData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getPackage : getPackageRole);
        if (data.status === true) {
            setApiPackage(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const areaData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        } else {
            handleApiError(response.status);
        }
    };

    const onSubmit = async (data) => {
        const search = data.search;
        const packageId = data.package;
        const area = data.area
        const expiry = data.expirydays
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        setFilters({
            search,
            start_date,
            end_date,
            packageId,
            area,
            expiry
        });
        fetchSubSummary(currentPage, perPage, search, start_date, end_date, packageId, area, expiry);
    };

    const fetchSubSummary = async (page, size = perPage, search = '', startDate = '', endDate = '', packageId = '', areaId = '', expriry_date = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewSubscriptionReport +
            `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&package_id=${packageId}&area_id=${areaId}&expiry_date=${expriry_date}` :
            viewSubscriptionReportRole + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&package_id=${packageId}&area_id=${areaId}&expiry_date=${expriry_date}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const expiringData = response.data.expiring;
            setStaticWidgetsData([
                {
                    id: 1,
                    title: 'Expired',
                    counter: expiringData.expired,
                    svg: <MessagesSvg />,
                    class: 'progress-gradient-primary',
                    progress: '75%'
                },
                {
                    id: 2,
                    title: 'Expiring Today',
                    counter: expiringData.today,
                    svg: <MessagesSvg />,
                    class: 'progress-gradient-primary',
                    progress: '60%'
                },
                {
                    id: 3,
                    title: 'Expiring Tomorrow',
                    counter: expiringData.tomorrow,
                    svg: <MessagesSvg />,
                    class: 'progress-gradient-primary',
                    progress: '48%'
                },
                {
                    id: 4,
                    title: 'Expiring in 5 days',
                    counter: expiringData.in_five_days,
                    svg: <MessagesSvg />,
                    class: 'progress-gradient-primary',
                    progress: '48%'
                }
            ]);
            const valueSubscription = response.data.subscriptions.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(Object.keys(valueSubscription).map((e, index) => {
                return {
                    Si: SerialNo + index,
                    SubId: valueSubscription[e].subscriber_id,
                    Name: valueSubscription[e].customer_name,
                    Phone: valueSubscription[e].customer_phone,
                    Area: valueSubscription[e].area_name,
                    CurrentPackage: valueSubscription[e].package_name,
                    ActiveDate: valueSubscription[e].activate_date,
                    ExpireDate: valueSubscription[e].expire_date,
                    DaysLeft: valueSubscription[e].days_left,
                    Status: valueSubscription[e].subscription_status,
                    customer_id: valueSubscription[e].customer_id,
                }
            }));
            setTotalRows(response.data.subscriptions.total);
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    const exportSubSummaryExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewSubscriptionReport +
            `?export=sub_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&package_id=${filters?.package ?? ''}&area_id=${filters?.areaFilter ?? ''}&expiry_date=${filters?.expiry ?? ''}` :
            viewSubscriptionReportRole + `?export=sub_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&package_id=${filters?.package ?? ''}&area_id=${filters?.areaFilter ?? ''}&expiry_date=${filters?.expiry ?? ''}`);
        if (response.status === true) {
            const dataToExport = response.data.subscriptions.map(({ subscriber_id, subscription_status, customer_name, customer_phone, area_name, package_name, activate_date, expire_date, days_left }) => ({ Subscriber_id: subscriber_id, Status: subscription_status, Name: customer_name, Phone_No: customer_phone, Area: area_name, Current_Package: package_name, Active_Date: activate_date, Expire_Date: expire_date, Days_Left: days_left }));
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Subscription Summary Data');
            XLSX.writeFile(wb, 'sububscription_report_data.xlsx');
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };

    const exportSubSummaryPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewSubscriptionReport +
            `?export=sub_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&package_id=${filters?.package ?? ''}&area_id=${filters?.areaFilter ?? ''}&expiry_date=${filters?.expiry ?? ''}` :
            viewSubscriptionReportRole + `?export=sub_summary_report&search=${filters?.searchKey ? filters.searchKey : ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}&package_id=${filters?.package ?? ''}&area_id=${filters?.areaFilter ?? ''}&expiry_date=${filters?.expiry ?? ''}`);
        if (response.status === true) {
            const dataToExport = response.data.subscriptions.map(({ subscriber_id, subscription_status, customer_name, customer_phone, area_name, package_name, activate_date, expire_date, days_left }, index) => ({ Sl: index + 1, subscriber_id, subscription_status, customer_name, customer_phone, area_name, package_name, activate_date, expire_date, days_left }));
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber id", "Status", "Name", "Phone No", "Area", "Current Package", "Active Date", "Expire Date", "Days Left"];
            const rows = dataToExport.map(({ subscriber_id, subscription_status, customer_name, customer_phone, area_name, package_name, activate_date, expire_date, days_left }, index) => [index + 1, subscriber_id, subscription_status, customer_name, customer_phone, area_name, package_name, activate_date, expire_date, days_left]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("Subsciption_report_report.pdf");
        } else {
            handleApiError(response.status);
        }
        setLoading(false);
    };



    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={SubscriptionSummaryRep} parent="Reports" title={SubscriptionSummaryRep} />
            <Progress details={StaticWidgetsData} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{SubscriptionSummaryList}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={exportSubSummaryExcel}><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={exportSubSummaryPdf}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text" {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('package')}>
                                <option value="">{Package}</option>
                                {apiPackage.map((values, index) => (
                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('area')}>
                                <option value="">{Area}</option>
                                {apiArea.map((values, index) => (

                                    <option key={index} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select" {...register('expirydays')}>
                                <option value="">{ExpiryDays}</option>
                                {expiryOptions.map((option, index) => (
                                    <option key={index} value={option.value}>{option.label}</option>
                                ))}
                            </select>
                        </Col>
                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>

                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={SubscriptionSummaryColumns}
                            data={customerList}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[ 10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            conditionalRowStyles={conditionalRow}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default SubscriptionSummary;
