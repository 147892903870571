import React, { Fragment, useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Form, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { H3, Breadcrumbs, Btn, H4 } from '../../AbstractElements';
import { Link, useNavigate } from 'react-router-dom';
import { getClientUrlDataToken } from '../../Utils/restUtils';
import { OnlinePaymentRep, OnlinePaymentList, Search, Filter, Area, Export } from '../../Constant';
import { getAreaRole, getOnlinePayment, getOnlinePaymentRole } from '../../CommonUrl';
import { getArea } from '../../CommonUrl';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import Transparentspinner from '../Common/Loader/Transparentspinner';

const OnlinePaymentReport = () => {
    const { register, handleSubmit, control, formState: { errors } } = useForm({});
    const navigate = useNavigate();
    const authState = useSelector((state) => state.auth);
    const [customerLists, setCustomerList] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange
    const [apiArea, setapiArea] = useState([]);
    const toggleStyle = { padding: '10px 20px', opacity: '0.8', fontSize: '14px' };
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filters, setFilters] = useState({
        searchKey: '',
        start_date: '',
        end_date: '',
        areaFilter: ''
    });

    useEffect(() => {
        fetchOnlinePay(1);
        areaData();
    }, []);

    const OnlinePaymentColumns = [
        {
            name: <H4>Sl</H4>,
            selector: row => row.Si,
            sortable: true,
            center: false,
            width: '55px',
            wrap: true
        },
        {
            name: <H4>Subscriber Id</H4>,
            selector: row =>
                (authState.userRole === 1 || authState.permissions.includes('show-customer-profile')) ?
                    <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile?id=${row.customer_id}`}>
                        {row.SubId}
                    </Link> : row.SubId,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Invoice No.</H4>,
            selector: row =>
                <Link style={{ textDecoration: 'underline' }} to={`${process.env.PUBLIC_URL}/customers/customer/userProfile/invoice?invoice_id=${row.id}&customer_id=${row.id}`}>
                    {row.InvoiceNo}
                </Link>,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Name</H4>,
            selector: row => row.Name,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Address</H4>,
            selector: row => row.Address,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Area</H4>,
            selector: row => row.Area,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Account</H4>,
            selector: row => row.Account,
            sortable: true,
            center: false,
            wrap: true
        },
        {
            name: <H4>Amount Paid</H4>,
            selector: row => row.AmountPaid,
            sortable: true,
            center: true,
            wrap: true
        },
        {
            name: <H4>Paid Date</H4>,
            selector: row => row.PaidDate,
            sortable: true,
            center: false,
            wrap: true,
        },
        {
            name: <H4>Paid By</H4>,
            selector: row => row.PaidBy,
            sortable: true,
            center: false,
            wrap: true,
        },
    
    ];

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    const handleApiError = (status) => {
        if (status >= 400 && status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (status >= 500 && status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    };

    const areaData = async () => {
        const data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getArea : getAreaRole);
        if (data.status === true) {
            setapiArea(data.data);
        } else {
            handleApiError(data.status);
        }
    };

    const handlePageChange = page => {
        fetchOnlinePay(page, perPage, filters.searchKey, filters.start_date, filters.end_date, filters.areaFilter);
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        fetchOnlinePay(page, newPerPage, filters.searchKey, filters.start_date, filters.end_date, filters.areaFilter);
        setPerPage(newPerPage);
    };

    const onSubmit = async (data) => {
        const searchKey = data.search;
        const start_date = startDate ? moment(startDate).format("DD-MM-YYYY") : '';
        const end_date = endDate ? moment(endDate).format("DD-MM-YYYY") : '';
        const areaFilter = data.area;
        setFilters({
            searchKey,
            start_date,
            end_date,
            areaFilter,
        });
        fetchOnlinePay(currentPage, perPage, searchKey, start_date, end_date, areaFilter);
    };

    const fetchOnlinePay = async (page, size = perPage, search = '', startDate = '', endDate = '', areaId = '') => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOnlinePayment +
            `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}` :
            getOnlinePaymentRole + `?page=${page}&page_length=${size}&search=${search}&start_date=${startDate}&end_date=${endDate}&area_id=${areaId}`);
        if (response.status === true) {
            setResetPaginationToggle(!resetPaginationToggle);
            const api_data = response.data.data;
            const SerialNo = (page - 1) * size + 1;
            setCustomerList(api_data.map((e, index) => {
                return {
                    Si: SerialNo + index,
                    SubId: e.subscriber_id,
                    InvoiceNo: e.invoice_no,
                    Name: e.customer_name,
                    Address: e.billing_address,
                    Area: e.area_name,
                    Account: e.account_name,
                    AmountPaid: authState.currency + " " + e.paid_amount,
                    PaidDate: e.paid_date,
                    PaidBy: e.paid_by,
                    id: e.order_id,
                    customer_id: e.customer_id,
                }
            }
            ))
            setTotalRows(response.data.total);
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportOnlinePayExcel = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOnlinePayment +
            `?export=online_payment_data&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}` :
            getOnlinePaymentRole + `?export=online_payment_data&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}`);
        const dataToExport = response.data.map(({ subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }) => ({ Subscriber_Id: subscriber_id, Invoice_No: invoice_no, Name: customer_name, Address: billing_address, Area: area_name, Account: account_name, Amount_Paid: authState.currency + paid_amount, Paid_Date: paid_date, Paid_By: paid_by }));
        if (response.status === true) {
            const ws = XLSX.utils.json_to_sheet(dataToExport);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'online_report');
            XLSX.writeFile(wb, 'online_payment_report_data.xlsx');
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    const exportOnlinePayPdf = async () => {
        setLoading(true);
        const response = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getOnlinePayment +
            `?export=online_payment_data&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}` :
            getOnlinePaymentRole + `?export=online_payment_data&search=${filters?.searchKey ?? ''}&area_id=${filters?.areaFilter ?? ''}&start_date=${filters?.start_date ?? ''}&end_date=${filters?.end_date ?? ''}`);
        const dataToExport = response.data.map(({ subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }, index) => ({ Sl: index + 1, subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }));
        if (response.status === true) {
            const doc = new jsPDF();
            const columns = ["Sl", "Subscriber Id", "Invoice No", "Name", "Address", "Area", "Account", "Amount Paid", "Paid Date", "Paid By"];
            const rows = dataToExport.map(({ subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by }, index) => [index + 1, subscriber_id, invoice_no, customer_name, billing_address, area_name, account_name, paid_amount, paid_date, paid_by]);
            doc.autoTable({
                head: [columns],
                body: rows
            });
            doc.save("online_payment_report.pdf");
        } else {
            handleApiError(data.status);
        }
        setLoading(false);
    };

    return (
        <Fragment>
            {loading && <Transparentspinner />}
            <Breadcrumbs mainTitle={OnlinePaymentRep} parent="Reports" title={OnlinePaymentRep} />
            <Card className='cardWidth'>
                <CardHeader style={{ margin: '-25px 0 -25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <H3>{OnlinePaymentList}</H3>
                    <div>
                        {(authState.userRole === 1 || authState.permissions.includes('export-report')) &&
                            <CardBody className="dropdown-basic">
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle color="#fff" style={{ fontSize: '14px', zIndex: 1000 }} className="btn btn-primary mr-4">
                                        {Export} <i className="icofont icofont-arrow-up"></i>
                                    </DropdownToggle>
                                    <DropdownMenu container="body">
                                        <DropdownItem style={toggleStyle} onClick={() => exportOnlinePayExcel()} ><i className="fa fa-file-excel-o me-2"></i> Excel</DropdownItem>
                                        <DropdownItem style={toggleStyle} onClick={() => exportOnlinePayPdf()}><i className="fa fa-file-pdf-o me-2"></i> Pdf</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardBody>
                        }
                    </div>
                </CardHeader>

                <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Row className="ps-3 gap-sm-3 gap-md-2 filterGap">
                        <Col sm="12" md='5' lg='2'>
                            <input className="form-control" placeholder={Search} type="text"
                                {...register('search')} />
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element" >
                            <div style={{ position: 'relative', zIndex: 2 }}>
                                <DatePicker
                                    className="form-control digits w-100"
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    isClearable={true}
                                    monthsShown={2}
                                    popperPlacement="bottom"
                                    calendarClassName='parallel-calendar'
                                    placeholderText='Select Date Range'
                                />
                            </div>
                        </Col>
                        <Col sm="12" md="5" lg='2' className="form-element">
                            <select className="form-select"  {...register('area')}>
                                <option key='areaa' value="">{Area}</option>
                                {apiArea.map((values, index) => (
                                    <option key={values.id} value={values.id}>{values.name}</option>
                                ))}
                            </select>
                        </Col>

                        <Col sm="12" md="5" lg='1' className="custom-filtrs">
                            <Btn attrBtn={{ color: "primary" }} type="submit">{Filter}</Btn>
                        </Col>
                    </Row>
                </Form>
                <CardBody>
                    <div className="order-history table-responsive">
                        <DataTable
                            columns={OnlinePaymentColumns}
                            data={customerLists}
                            pagination
                            paginationServer
                            defaultSortAsc
                            paginationRowsPerPageOptions={[ 10, 25, 50, 100]}
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            fixedHeader
                        />
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default OnlinePaymentReport;