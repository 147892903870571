import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from 'reactstrap';
import { Btn, H3, Breadcrumbs } from "../../../AbstractElements";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Account, AccountEdi, AccountNo, BankAddress, BankNameText, BankPhone, Name, OpeningBalance, Save } from "../../../Constant";
import { editAccount, editAccountRole, viewAccount, viewAccountRole } from "../../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../../Utils/restUtils";
import { toast } from 'react-toastify';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from "../../Common/Loader/Transparentspinner";

const schema = yup.object().shape({
    name: yup.string().required().label('Name'),
    balance: yup.string().required().label('Opening Balance'),
    accountNo: yup.string().required().label('Account Number'),
})

const AccountEdit = () => {

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const authState = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = location.search;
    const params = new URLSearchParams(searchParams);
    const param1 = params.get("id");


    useEffect(() => {
        getSingleIdData();

    }, []);
    const getSingleIdData = async () => {
        setLoading(true);
        let apiData = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? viewAccount + `?account_id=${param1}` : viewAccountRole + `?account_id=${param1}`);
        if (apiData.status === true) {
            const apiSingleId = apiData?.data[0];
            setValue("name", apiSingleId.name);
            setValue("accountNo", apiSingleId.account_number);
            setValue("balance", apiSingleId.opening_balance);
            setValue("bankName", apiSingleId.bank_name);
            setValue("bankPhone", apiSingleId.bank_phone);
            setValue("address", apiSingleId.bank_address || "");
        }

        else if (apiData.status >= 400 && apiData.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (apiData.status >= 500 && apiData.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setLoading(false);
    }

    const onSubmit = async (data) => {
        setLoading(true);
        const postRequest = {
            account_id: param1,
            name: data.name,
            opening_balance: data.balance,
            bank_name: data.bankName,
            bank_phone: data.bankPhone,
            bank_address: data.address,
            account_number: data.accountNo
        }
        const updateAccountResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? 
            editAccount : editAccountRole, postRequest);
        if (updateAccountResponse.status === 200) {
            if (updateAccountResponse.data.status === true) {
                navigate(`${process.env.PUBLIC_URL}/banking/account`);
                toast.success(updateAccountResponse.data.message)
            } else {
                setLoading(false);
                toast.error(addAreaResponse.data.message)
            }
        } else if (updateAccountResponse.status >= 400 && updateAccountResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (updateAccountResponse.status >= 500 && updateAccountResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
        setLoading(false);
    };
    return (
        <Fragment>
            {loading && (
                <Transparentspinner />
            )}
            <Breadcrumbs mainTitle={Account} parent="Banking" title={AccountEdi} />
            <Card>
                <CardHeader className="pb-0">
                    <H3>{AccountEdi}</H3>
                </CardHeader>
                <CardBody>
                    <Form className="needs-validation" noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Row className="g-3 pt-3">
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{Name} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('name', { required: true })} />
                                <p className="text-danger">{errors.name?.message}</p>
                            </Col>
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{AccountNo} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('accountNo', { required: true })} />
                                <p className="text-danger">{errors.accountNo?.message}</p>
                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">
                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{OpeningBalance} <span className="requireStar">*</span></Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('balance', { required: true })} />
                                <p className="text-danger">{errors.balance?.message}</p>
                            </Col>
                            <Col md='6'>
                                <Label className="form-label" for="validationCustom01">{BankNameText}</Label>
                                <input className="form-control" id="validationCustom01" type="text" {...register('bankName')} />

                            </Col>
                        </Row>
                        <Row className="g-3 pt-3">

                            <Col md="6">
                                <Label className="form-label" for="validationCustom02">{BankPhone}</Label>
                                <input className="form-control" id="validationCustom02" type="text" {...register('bankPhone')} />

                            </Col>
                            <Col>
                                <Label className="form-label">{BankAddress}</Label>
                                <textarea  {...register('address')} className="form-control" maxLength={250}/>
                            </Col>
                        </Row>
                        <Row className="g-3">

                        </Row>
                        <FormGroup>
                        </FormGroup>
                        <Btn attrBtn={{ color: "primary" }} type="submit">{Save}</Btn>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    )
}
export default AccountEdit;