import React, { Fragment, useState, useEffect } from "react";
import { Col, Card, CardHeader, CardBody, Form, FormGroup, Label, Row } from "reactstrap";
import { Btn, H3, Breadcrumbs } from "../../AbstractElements";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getHeaderRole, getHeaders, addIncome, addIncomeRole, selectVendor, selectVendorRole } from "../../CommonUrl";
import { useSelector } from "react-redux";
import { getClientUrlDataToken, postClientUrlWithToken } from "../../Utils/restUtils";
import { toast } from "react-toastify";
import DatePicker from 'react-datepicker';
import { Amount, Description, Save, IncomeAdd, Vendor, Header, BillDate, DueDate, BillNo, OrderNo, Income, AttachBills } from "../../Constant/index";
import Transparentspinner from "../Common/Loader/Transparentspinner";
import TypeaheadAdd from "../../CommonElements/TypeAheadAdd";
import Popup from "../Common/Popup/popup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import VendorAdd from "../Expenses/VendorAdd";
import HeaderAdd from "../Expenses/HeaderAdd";

const schema = yup.object().shape({
  vendor: yup.mixed().required().label('Vendor'),
  header: yup.mixed().required().label('Header'),
  amount: yup.string().required().label('Amount'),
});

const IncomeForm = () => {
  const { register, handleSubmit, control, formState: { errors }, setValue, getValues, } = useForm({
    resolver: yupResolver(schema),
  });
  const authState = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  const [modal, setModal] = useState(false);
  const [headerModal, setHeaderModal] = useState(false);
  const [vendorOption, setVendorOption] = useState([]);
  const [headerOption, setHeaderOption] = useState([]);
  const [billDate, setBillDate] = useState(new Date());
  const [dueDate, setDueDate] = useState(new Date());

  const form = new FormData();
  const changepwdtitile = <span className='billerqColor bolder '>
    <h3>Add Vendor</h3>
  </span>
  const changeheadtitle = <span className='billerqColor bolder '>
    <h3>Add Header</h3>
  </span>
  const navigate = useNavigate();

  useEffect(() => {
    vendorData();
    headerData();
  }, []);

  const handleImageChange = (event) => {

    const selectedFile = event.target.files[0];
    setImage(selectedFile);

  }

  const handleChangeBillDate = date => {
    setBillDate(date);
  };
  const handleChangeDueDate = date => {
    setDueDate(date);
  };

  const handleModal = (id) => {
    setModal(!modal);
  }
  const handleHeaderModal = (id) => {
    setHeaderModal(!headerModal);
  }

  const vendorData = async () => {
    let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? selectVendor : selectVendorRole);
    if (data.status === true) {
      const vendorList = data.data.map(vendor => ({ value: vendor.id, label: vendor.title }));
      setVendorOption(vendorList);
      return vendorList;
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

    }
  };

  const headerData = async () => {
    let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getHeaders : getHeaderRole);
    if (data.status === true) {
      const headerList = data.data.map(header => ({ value: header.id, label: header.title }));
      setHeaderOption(headerList);
      return headerList;
    }
    else if (data.status >= 400 && data.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (data.status >= 500 && data.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    }
    else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
  };

  const handleVendorAdded = async (newVendorTitle) => {
    const updatedVendorOptions = await vendorData();
    const vendor = updatedVendorOptions.find(v => v.label === newVendorTitle);
    if (vendor) {
      setValue('vendor', vendor);
    }
  };

  const handleHeaderAdded = async (newHeaderTitle) => {
    const updatedHeaderOptions = await headerData();
    const header = updatedHeaderOptions.find(v => v.label === newHeaderTitle);
    if (header) {
      setValue('header', header);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const formattedBillDate = billDate ? moment(billDate) : "";
    const formattedDueDate = dueDate ? moment(dueDate) : "";

    form.append("vendor_id", data.vendor.value)
    form.append("header_id", data.header.value)
    form.append("amount", data.amount)
    form.append("bill_date", formattedBillDate)
    form.append("bill_no", data.bill_no)
    form.append("order_no", data.order_no)
    form.append("due_date", formattedDueDate)
    form.append("description", data?.description ?? "")
    if (data.file[0]) {
      form.append("file", data.file[0])
    }
    const addPackagesResponse = await postClientUrlWithToken(authState.apiUrl, authState.userRole === 1 ? addIncome : addIncomeRole, form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (addPackagesResponse.status === 200) {
      if (addPackagesResponse.data.status === true) {
        toast.success(addPackagesResponse.data.message)
        navigate(`${process.env.PUBLIC_URL}/Expenses/Income`);
      } else {
        setLoading(false);
        toast.error(
          Array.isArray(addPackagesResponse.data.message)
            ? addPackagesResponse.data.message.map((e) => e)
            : "Unexpected error occurred"
        );
      }
    } else if (addPackagesResponse.status >= 400 && addPackagesResponse.status <= 405) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
    } else if (addPackagesResponse.status >= 500 && addPackagesResponse.status <= 505) {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
    } else {
      navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      {loading && <Transparentspinner />}

      <Breadcrumbs mainTitle={Income} parent="Expenses" title={IncomeAdd} />
      <Card>
        <CardHeader className="pb-0">
          <H3>{IncomeAdd}</H3>
        </CardHeader>
        <CardBody>
          <Form
            className="needs-validation"
            noValidate
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="g-3 pt-2">
              <Col md="6">
                <Label className="form-label">
                  {Vendor} <span className="requireStar">*</span>
                </Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="vendor"
                    control={control}
                    render={({ field }) => (
                      <TypeaheadAdd
                        placeholder="select..."
                        options={vendorOption}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        value={field.value}
                      />
                    )}
                  />
                  {(authState.userRole === 1 || authState.permissions.includes('store-vendor')) &&
                    <div className="plusDesign" onClick={() => handleModal()}>
                      +
                    </div>
                  }
                </div>
                <p className="text-danger">{errors.vendor?.message}</p>
              </Col>
              <Col md="6">
                <Label className="form-label">
                  {Header} <span className="requireStar">*</span>
                </Label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Controller
                    name="header"
                    control={control}
                    render={({ field }) => (
                      <TypeaheadAdd
                        placeholder="select..."
                        options={headerOption}
                        onChange={(value) => {
                          field.onChange(value);

                        }}
                        value={field.value}
                      />
                    )}
                  />
                  {(authState.userRole === 1 || authState.permissions.includes('store-header')) &&
                    <div className="plusDesign" onClick={() => handleHeaderModal()}>
                      +
                    </div>
                  }
                </div>
                <p className="text-danger">{errors.header?.message}</p>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >
                  {Amount} <span className="requireStar">*</span>
                </Label>
                <input
                  name="amount"
                  className="form-control"
                  id="amount"
                  type="number"
                  min="0"
                  {...register("amount", { required: true })}
                />
                <p className="text-danger">{errors.amount?.message}</p>
              </Col>
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" >{BillDate} <span className="requireStar">*</span></Label>
                  <Col xl='12' md="12" sm='12'>
                    <DatePicker
                      id="bill"
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={handleChangeBillDate}
                      // peekNextMonth
                      selected={billDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      required
                    />
                  </Col>

                </FormGroup>
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <FormGroup className="mb-3">
                  <Label className="form-label" >{DueDate} <span className="requireStar">*</span></Label>
                  <Col xl='12' md="12" sm='12'>
                    <DatePicker
                      id="due"
                      style={{ padding: '12px 100px' }}
                      className="form-control "
                      dateFormat="dd/MM/yyyy"
                      placeholderText="dd/mm/yyyy"
                      onChange={handleChangeDueDate}
                      selected={dueDate}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      required
                    />
                  </Col>
                  <p className="text-danger">{errors.invoice_date?.message}</p>
                </FormGroup>
              </Col>
              <Col md="6">
                <Label className="form-label" > {BillNo} </Label>
                <input
                  name="bill_no"
                  className="form-control"
                  id="billNo"
                  type="number"
                  {...register("bill_no")}
                />
              </Col>
            </Row>
            <Row className="g-3 pt-3">
              <Col md="6">
                <Label className="form-label" >{OrderNo}</Label>
                <input
                  name="order_no"
                  className="form-control"
                  id="orderNo"
                  type="number"

                  {...register("order_no")}
                />
              </Col>
              <Col md="6">
                <Label className="form-label">{AttachBills}</Label>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <Col md="8">
                        <input
                          {...register("file")}
                          className="form-control fileInputStyle"
                          type="file"
                          onChange={handleImageChange}
                          accept=".png, .jpg, .jpeg"
                        />
                      </Col>
                      <Col md='4'>
                        <span className="imagestyle">
                          {image && <img src={URL.createObjectURL(image)} alt="logo" width="40px" height="40px" />}
                        </span>
                      </Col>
                    </div>
                  )}
                />
                <span className="text-danger">{errors.logo && "logo is required"}</span>
              </Col>
            </Row>

            <Row className="g-3">
              <Col>
                <Label className="form-label pt-3">{Description}</Label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <textarea className="form-control" {...field} rows="3" maxLength={300}/>
                  )}
                />
              </Col>
            </Row>
            <FormGroup></FormGroup>
            <Btn attrBtn={{ color: "primary" }} type="submit">
              {Save}
            </Btn>
          </Form>
        </CardBody>
      </Card>
      <Popup isOpen={modal} title={changepwdtitile} toggler={handleModal} >
        <VendorAdd
          setModal={setModal} handleVendorAdded={handleVendorAdded}
        />
      </Popup>
      <Popup isOpen={headerModal} title={changeheadtitle} toggler={handleHeaderModal} >
        <HeaderAdd
          setHeaderModal={setHeaderModal} handleHeaderAdded={handleHeaderAdded}
        />
      </Popup>
    </Fragment >
  )
}

export default IncomeForm;
