import React, { useEffect, useState } from 'react'
import { Category, DueDate, ImportModalNote, InvoiceDate, PlanDate, Save, Tax } from '../../../Constant'
import { CardHeader, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import { Controller, useForm } from "react-hook-form"
import { Btn } from '../../../AbstractElements'
import { getClientUrlDataToken, postClientUrlWithToken } from '../../../Utils/restUtils'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Transparentspinner from '../Loader/Transparentspinner'
import { getCategoryRole, getcategoryincome, statusTax, statusTaxRole } from '../../../CommonUrl'
import Typeahead from '../../../CommonElements/TypeAhead';
import DatePicker from 'react-datepicker';
import moment from 'moment'


const schema = yup.object().shape({
    excelFile: yup
        .mixed().required()
        .test('fileType', 'Please upload an Excel file (.xls or .xlsx)', (value) => {
            if (!value) return true;
            return ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].includes(value[0]?.type);
        }),
    tax: yup.mixed().required().label('Tax'),

});

const ImportDuePopUp = ({ setModalImport, sampleImportApi, postImportApi, imporTitle, tablerenderFunc }) => {
    const authState = useSelector(state => state.auth);
    const navigate = useNavigate();
    const { register, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const form = new FormData();
    const [spinnerTrans, setspinnerTrans] = useState(false);
    const [category, setCategory] = useState([]);
    const [categoryOption, setCategoryOption] = useState([]);
    const [taxOption, setTaxOption] = useState([]);
    const [startDate, setstartDate] = useState(new Date());
    const [planStart, setplanStart] = useState(new Date());

    useEffect(() => {
        taxData();
        categoryData();
    }, [])

    useEffect(() => {
        setCategoryOption(
            category.map((data) => ({
                value: data.id,
                label: data.name,
            }))
        );
    }, [category]);

    const taxData = async () => {
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? statusTax : statusTaxRole);
        if (data.status == true) {
            const getTax = data.data.map((data) => ({
                value: data.id,
                label: data.title,
            }));
            setTaxOption(getTax);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
    };

    const categoryData = async () => {
        setspinnerTrans(true)
        let data = await getClientUrlDataToken(authState.apiUrl, authState.userRole === 1 ? getcategoryincome : getCategoryRole);
        if (data.status === true) {
            setCategory(data.data);
        }
        else if (data.status >= 400 && data.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (data.status >= 500 && data.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        }
        else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);

        }
        setspinnerTrans(false)
    };

    const handleChangeInvoicedate = date => {
        setstartDate(date);
        setplanStart(date);
    };

    const handleChangePlanedate = date => {
        setplanStart(date);
    };


    const submit = async (data) => {
        const invoice_Date = moment(startDate).format("DD-MM-YYYY");
        const plane_Date = moment(planStart).format("DD-MM-YYYY");
        setspinnerTrans(true);
        form.append("file", data.excelFile[0]);
        form.append("tax_group_id", data.tax.value);
        form.append("invoice_date", invoice_Date);
        form.append("due_date", plane_Date);
        const addCustomerResponse = await postClientUrlWithToken(
            authState.apiUrl, postImportApi, form, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }
        );
        if (addCustomerResponse.status === 200) {
            if (addCustomerResponse.data.status === true) {
                toast.success(addCustomerResponse.data.message);
                setModalImport(false);
                setspinnerTrans(false);
                tablerenderFunc();
            } else {
                toast.error(addCustomerResponse.data.message)
                setspinnerTrans(false);
            }
        } else if (addCustomerResponse.status >= 400 && addCustomerResponse.status <= 405) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page1`);
        } else if (addCustomerResponse.status >= 500 && addCustomerResponse.status <= 505) {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page4`);
        } else {
            navigate(`${process.env.PUBLIC_URL}/pages/error/error-page3`);
        }
    }


    return (
        <>
            {spinnerTrans && <Transparentspinner />}
            <Form id="form1" onSubmit={handleSubmit(submit)}>
                <Row className="p-2">
                    <Col md={12} lg={12}>
                        <CardHeader className="">
                            <h5 style={{ color: '#534686' }}>{imporTitle}</h5>
                        </CardHeader>

                        <input type="file" {...register('excelFile', { required: true })} accept='.xls,.xlsx'
                            style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '5px', width: '100%' }}
                        />
                        <div className='d-flex justify-content-between mt-1'>
                            {errors.excelFile ? (<span style={{ color: 'red' }}>Please upload an Excel file (.xls or .xlsx)</span>) : (
                                <span></span>
                            )}
                            <span> <i className="icofont icofont-cloud-download fs-5" style={{ color: '#534686' }}></i> <a href={sampleImportApi} ><b>Sample file</b></a></span>
                        </div>
                    </Col>
                </Row>
                <Row className="ps-2  mb-3">
                    <Col md="6">
                        <Label className="form-label" for="validationCustom02">{Tax} <span className="requireStar">*</span> </Label>
                        <Controller
                            name="tax"
                            control={control}
                            render={({ field }) => (
                                <Typeahead
                                    options={taxOption}
                                    onChange={(value) => {
                                        field.onChange(value);
                                    }}
                                    value={field.value}
                                />
                            )}
                        />
                        <p style={{ color: 'red' }}>{errors.tax?.message}</p>
                    </Col>
                    <Col md="6">
                        <Label className="form-label" >{Category} <span className="requireStar">*</span></Label>
                        <Controller
                            name="category"
                            control={control}
                            render={({ field }) => (
                                <Typeahead
                                    options={categoryOption}
                                    onChange={(value) => {
                                        field.onChange(value);
                                    }}
                                    value={field.value}
                                />
                            )}
                        />
                        <p className="text-danger">{errors.category?.message}</p>
                    </Col>
                </Row>

                <Row className="ps-2  mb-3">
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <Label className="form-label" for="invoice_date">{InvoiceDate} <span className="requireStar">*</span></Label>
                            <Col xl='12' md="12" sm='12'>
                                <DatePicker
                                    id="invoice_date"
                                    style={{ padding: '12px 100px' }}
                                    className="form-control "
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="dd/mm/yyyy"
                                    selected={startDate}
                                    onChange={handleChangeInvoicedate}
                                    required
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <Label className="form-label" for="plan_date">{DueDate}  <span className="requireStar">*</span></Label>
                            <DatePicker
                                id="plan_date"
                                style={{ padding: '12px 100px' }}
                                className="form-control "
                                dateFormat="dd/MM/yyyy"
                                placeholderText="dd/mm/yyyy"
                                selected={planStart}
                                onChange={handleChangePlanedate}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row className="ps-2  mb-3">
                    <span className=''>
                        <Btn attrBtn={{ color: "primary", className: "btn" }} type="submit">
                            <span>
                                {Save}
                            </span>
                        </Btn>
                    </span>
                </Row>

            </Form>
        </>
    )
}

export default ImportDuePopUp